<template>
  <b-container fluid>
    <b-form @submit.prevent="send">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Compose Email</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col cols="12">
          <b-card>
            <div class="d-flex flex-column flex-md-row justify-content-between">
              <div class="d-flex flex-column justify-content-center">
                <p class="h3 font-weight-bold text-muted mb-0">Customer</p>
                <p class="mb-0 text-muted">Who is the recipient of this email?</p>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                <customer-search
                  :error-feed="errors.errors"
                  :error-key="'customer_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="customer"
                  v-model="composable.customer"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col cols="12">
          <b-card>
            <div class="d-flex flex-column flex-md-row justify-content-between">
              <div class="d-flex flex-column justify-content-center">
                <p class="h3 font-weight-bold text-muted mb-0">Template</p>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                <template-search
                  :error-feed="errors.errors"
                  :error-key="'template_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="template"
                  @input="templateUpdated"
                  v-model="template"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols='12'>
                <h4>Email Content</h4>
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols='12'>
                <b-form-group label="Subject">
                  <b-form-input v-model="composable.subject"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-column justify-content-center">
                          <p class="h3 font-weight-bold text-muted mb-0">Schedule Email</p>
                        </div>
                        <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                          <b-form-checkbox v-model="setEmailSchedule"></b-form-checkbox>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="setEmailSchedule">
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group class="mb-0">
                        <b-form-datepicker placeholder="Scheduled Date" v-model="composable.scheduled_at"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="mt-3 mt-md-0 md-0">
                      <b-form-group class="mb-0">
                        <b-input placeholder="Scheduled Time (HH:MM)" v-model="composable.scheduled_time"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <label>Content: </label>
                <Quill @delta="delta => template.delta_content = delta" :new-content="templateContent" v-model="composable.content"/>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col class="d-flex flex-row" cols="12" role="button">
                      <div class="container-fluid">
                        <b-row>
                          <b-col md="7" class="d-flex flex-row" @click.prevent="toggleCollapse('email_attachments')">
                            <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['email_attachments'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                            <div class="d-flex flex-column flex-fill ml-4 justify-content-center">
                              <p class="h4 font-weight-bold mb-0">Attachments</p>
                            </div>
                          </b-col>
                          <b-col md="5" class="d-flex flex-row align-items-center justify-md-content-end pt-3 pt-md-0 pr-0">
                            <b-button variant="outline-secondary" :disabled="attachmentButtonClicked" @click="newFileUpload"><fa-icon icon="plus" /> Add Attachment</b-button>
                            <b-form-file v-show="false" @change="collapsed['email_attachments'] = false" autofocus v-for="(attachment, attachmentInd) in composable.attachments" v-model="composable.attachments[attachmentInd]" :key="attachmentInd" :id="`attachment-upload${attachmentInd}`"></b-form-file>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4" v-if="!collapsed['email_attachments']">
                    <b-col cols="12">
                      <b-row>
                        <b-col cols="12">
                          <hr class="mt-0">
                        </b-col>
                      </b-row>
                      <b-row v-if="filteredNewEmailAttachments.length">
                        <b-col cols="12" class="">
                          <div class="d-flex flex-row flex-fill flex-wrap">
                            <span v-for="(attachment, attachmentInd) in composable.attachments" :key="`email-attachment${attachmentInd}`" v-show="attachment != null"><b-button :variant="hoveredAttachment == attachmentInd ? 'outline-danger' : 'outline-primary'" @mouseover="hoveredAttachment = attachmentInd" @mouseleave="hoveredAttachment = null" @click="rmAttachment(attachmentInd)" class="mr-2 mb-2" v-if="attachment != null"><fa-icon :icon="hoveredAttachment == attachmentInd ? 'times' : 'file-import'" class="mr-2"/>{{ attachment.name }}</b-button></span>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="!filteredNewEmailAttachments.length">
                        <b-col cols="12" class="d-flex justify-content-center">
                          <p class="mt-1 mb-0">There are no attachments currently selected.</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-column justify-content-center">
                          <p class="h3 font-weight-bold text-muted mb-0">Set Reminder</p>
                        </div>
                        <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                          <b-form-checkbox v-model="setEmailReminder"></b-form-checkbox>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="setEmailReminder">
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group class="mb-0">
                        <b-form-datepicker placeholder="Reminder Date" v-model="reminder.date"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="mt-3 mt-md-0 md-0">
                      <b-form-group class="mb-0">
                        <b-input placeholder="Reminder Time (HH:MM)" v-model="reminder.time"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                      <b-form-group class="mb-0" label="Note">
                        <b-form-textarea v-model="reminder.note"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="!isLoading" class="mt-4">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import TemplateSearch from '../../components/TemplateSearch'
import CustomerSearch from '../../components/CustomerSearch'
import Quill from '../../components/Quill'

export default {
  mixins: [validation, momentMixin],
  components: { TemplateSearch, CustomerSearch, Quill },
  created () {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('emails', ['errors', 'isLoading', 'isSaving', 'single']),
    filteredNewEmailAttachments() {
      return this.composable.attachments.filter(attachment => {
        return (attachment != null)
      })
    },
  },
  data () {
    return {
      collapsed: {
        email_attachments: true
      },

      template: { id: null },

      templateContent: null,
      
      attachmentButtonClicked: false,
      hoveredAttachment: null,
      setEmailSchedule: false,

      composable: {
        id: null,
        customer: { id: null },
        subject: null,
        content: null,
        scheduled_at: null,
        scheduled_time: null,
        attachments: [],
      },

      setEmailReminder: false,

      reminder: {
        note: null,
        date: null,
        time: null,
      }
    }
  },
  methods: {
    ...mapActions('templates', ['fetch']),
    ...mapActions('emails', ['send']),
    ...mapActions('customers', ['doQueueEmail']),

    templateUpdated() {
      this.templateContent = this.template.delta_content
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    rmAttachment(attachmentInd) {
      this.composable.attachments.splice(attachmentInd, 1)
      this.composable.attachments = this.composable.attachments.filter(attachment => {
        return (attachment != null)
      })
    },

    newFileUpload() {
      this.attachmentButtonClicked = true
      this.composable.attachments.push(null)
      this.$nextTick().then(() => {
        document.getElementById(`attachment-upload${this.composable.attachments.length-1}`).click()
        setTimeout(() => {
          this.attachmentButtonClicked = false
        }, 500)
      })
    },

    addNote() {
      if(this.newNote) {
        window.axios.post(`/customers/${this.customer.id}/note/add`, { note: this.newNote }).then(
          response => {
            if(response.data) {
              this.customer.notes = [{
                  id: response.data.id,
                  note: this.newNote,
                  user: { name: this.currentUser.name },
                  created_at: response.data.created_at
                }, ...this.customer.notes];
              this.newNote = null;
            }
          }
        )
      }
    },

    send () {
      let data = new FormData()

      data.append('subject', this.composable.subject)
      data.append('content', this.composable.content)
      data.append('scheduled_date', !this.setEmailSchedule ? this.moment().format('YYYY-MM-DD') : this.composable.scheduled_at)
      data.append('scheduled_time', !this.setEmailSchedule ? this.moment().format('HH:MM') : this.composable.scheduled_time)
      
      if(this.setEmailReminder) {
        data.append('reminder[note]', this.reminder.note)
        data.append('reminder[date]', this.reminder.date)
        data.append('reminder[time]', this.reminder.time)
      }

      this.composable.attachments.forEach((attachment, attachmentInd) => {
        if(attachment != null) data.append(`attachments[${attachmentInd}]`, attachment)
      })
      
      this.doQueueEmail({ 'customer': this.composable.customer.id, data}).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.emails.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Email Successfully ` + ( this.setEmailSchedule ? 'Queued.' : 'Sent.' ),
              text: `The email has been ` + ( this.setEmailSchedule ? 'queued.' : 'sent.' ),
              timeout: 2500
            }]
          }
        })
      })
    },
  },
  watch: {
    single (newUser) {
      this.email = this.single
    }
  }
}
</script>

<style>

</style>
