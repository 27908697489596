import pagination from '../../utils/pagination'
import {
  EMAILS_BATCH_DELETE_REQUEST, EMAILS_BATCH_DELETE_SUCCESS, EMAILS_BATCH_DELETE_FAILURE,
  EMAILS_FETCH_MANY_REQUEST, EMAILS_FETCH_MANY_SUCCESS, EMAILS_FETCH_MANY_FAILURE,
  EMAILS_FETCH_REQUEST, EMAILS_FETCH_SUCCESS, EMAILS_FETCH_FAILURE,
  EMAILS_SAVE_REQUEST, EMAILS_SAVE_SUCCESS, EMAILS_SAVE_FAILURE,

  EMPTY_EMAILS,
  CLEAR_ERRORS
} from './types'

export default {
  [EMAILS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [EMAILS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [EMAILS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EMAILS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [EMAILS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [EMAILS_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
