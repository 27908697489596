<template>
  <b-row class="mb-3">
    <b-col cols="12">
      <b-card>
          <b-row>
              <b-col cols="12">
                  <h4>Ticket Statuses:</h4>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12 d-flex flex-row flex-wrap">
                  <b-form-checkbox
                      v-for="status in ticketStatuses"
                      :key="`status-${status.status}`"
                      v-model="status.checked"
                      class="m-2">
                      {{ status.status }}
                  </b-form-checkbox>
              </b-col>
          </b-row>
      </b-card>
    </b-col>
    <b-col cols="12">
        <GmapMap
          :center="{lat:54, lng:-2}"
          :zoom="7"
          :options="{
            streetViewControl: false,
            disableDefaultUi: true,
            fullscreenControl: false,
            mapTypeContro: false,
          }"
          map-type-id="terrain"
          style="width: 100%; height: calc(100vh - 11.5rem)"
        >
            <GmapInfoWindow
              key="van"
              v-if="showVan"
              :options="{
                content: '<span>BL70 RWY</em></span>',
                pixelOffset: {
                  width: 0,
                  height: -35,
                }
              }"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"/>
            <GmapMarker
              v-if="showVan"
              key="van-marker"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"
            />
          <GmapInfoWindow
            :key="`info${index}`"
            :options="getInfoWindowOptions(index)"
            :opened="true"
            :position="{ lat:Number(ticket.ticket_address.latitude), lng: Number(ticket.ticket_address.longitude) }"
            v-for="(ticket, index) in computedTickets"/>
          <GmapMarker
            :key="`marker${index}`"
            v-for="(ticket, index) in computedTickets"
            :position="{ lat:Number(ticket.ticket_address.latitude), lng: Number(ticket.ticket_address.longitude) }"
          />
        </GmapMap>
    </b-col>
  </b-row>
</template>

<script>
import currentUser from '../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

window.ticketRedirection = function(id) {
  window.dispatchEvent(new CustomEvent('view-ticket', { detail: id }))
}

export default {
  mixins: [currentUser],
  props: {
      showMap: Boolean
  },
  created () {
    window.addEventListener('view-ticket', (payload) => {
      this.emitTicketClicked(
        this.computedTickets.find(ticket => (ticket.id == payload.detail))
      )
    })
    this.vanInterval = setInterval(() => {
      this.getVanMarkerCoords()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.vanInterval)
  },
  data: () => ({
    van: {},
    vanInterval: null,
    showVan: false,

    ticketStatuses: [
      { status: 'New Issue', checked: false },
      { status: 'Contacting Customer', checked: false },
      { status: 'Support Case', checked: false },
      { status: 'Pending Site Visit', checked: false },
      { status: 'Waiting For Cover', checked: false },
      { status: 'Waiting For Panel', checked: false },
      { status: 'Completed - Waiting For Parts From Superior', checked: false },
      { status: 'Completed', checked: false }
    ],
  }),
  computed: {
    ...mapGetters('tickets', ['data', 'isLoading']),

    computedTickets() {
        var selectedStatuses = this.ticketStatuses.filter(function(status) {
          return status.checked
        }).map(function(status) {
          return status.status
        })

        return this.data.filter(ticket => {
          if(ticket.ticket_address && (!isNaN(ticket.ticket_address.latitude) && !isNaN(ticket.ticket_address.longitude))) {
            if(selectedStatuses.length) {
              return selectedStatuses.includes(ticket.status) 
            }else{
              return ( ticket.status != 'Completed' && ticket.ticket_address && ( !isNaN(ticket.ticket_address.latitude) && !isNaN(ticket.ticket_address.longitude) ))
            }
          }
          return false
        })
    }

  },
  methods: {
    ...mapActions('tickets', ['fetchAll']),
    getVanMarkerCoords() {
      // Van 2 is BL70 RWY
      window.axios.get('/vans/2').then(response => {
        this.van = response.data
        this.showVan = true
      })
    },
    loadData(){
      this.fetchAll();
    },
    getInfoWindowOptions(ind) {
      return {
        content: '<span onclick="window.ticketRedirection('+this.computedTickets[ind].id+')">#' + this.computedTickets[ind].id + ' ' + this.computedTickets[ind].name + '<br><em>' + this.computedTickets[ind].status + '</em></span>',
        pixelOffset: {
          width: 0,
          height: -35,
        }
      }
    },
    emitTicketClicked(ticket) {
      this.$emit('ticketClicked', ticket)
    }
  },
}
</script>

<style lang="scss">

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }
</style>
