<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Repair Calendar</h1>
      </b-col>
    </b-row>

    <b-row class="mb-3">
        <b-col cols="12">
            <b-card>
                <b-row class="mb-3">
                    <b-col cols="12">
                        <h4>Search</h4>
                        <b-form-input v-model="calendarSearch" placeholder="Search..." @input="calendarData"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <h4>Teams</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12 d-flex flex-row flex-wrap">
                        <b-form-checkbox
                            v-for="team in storedTeams"
                            :key="`team${team.id}`"
                            @change="toggleTeamSelect(team.id)"
                            :checked="( selectedTeams.includes(team.id) )"
                            class="m-2">
                            {{ team.name }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
        <b-col cols="12">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <RepairFullCalendar
                            selectable
                            @select="viewCreateEvent"
                            :data="data"
                            :repairEvents="repairEvents"
                            @getData="calendarData"
                            @eventClick="eventClicked"
                            @eventDrop="eventDropped"
                            @eventResize="eventDropped"
                        ></RepairFullCalendar>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    
    <b-row>
        <b-col>
            <b-modal id="repair-event-modal" title="Create Event" centered hide-footer>
                <b-row>
                    <b-col cols="12">
                        <b-form @submit.prevent="doCreateEvent">
                            <b-form-group label="Name">
                                <b-form-input v-model="newEvent.name"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Description">
                                <b-form-textarea v-model="newEvent.description"></b-form-textarea>
                            </b-form-group>
                            <b-form-group label="Colour">
                                <b-form-select v-model="newEvent.colour">
                                    <b-form-select-option :value="null">Please select a colour</b-form-select-option>
                                    <b-form-select-option value="#B32727">Dark Red</b-form-select-option>
                                    <b-form-select-option value="#498C4B">Dark Green</b-form-select-option>
                                    <b-form-select-option value="#2E3145">Dark Blue</b-form-select-option>
                                    <b-form-select-option value="#4BB5A2">Turquoise</b-form-select-option>
                                    <b-form-select-option value="#AE48B0">Dark Purple</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="Team">
                                <team-search :error-feed="errors.errors" :error-key="'team_id'" :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated" v-model="newEvent.team" />
                            </b-form-group>
                            <b-form-group label="Start Date">
                                <b-form-datepicker v-model="newEvent.startDate"></b-form-datepicker>
                            </b-form-group>
                            <b-form-group label="Start Time">
                                <b-form-input v-model="newEvent.startTime" placeholder="HH:MM"></b-form-input>
                            </b-form-group>
                            <b-form-group label="End Date">
                                <b-form-datepicker v-model="newEvent.endDate"></b-form-datepicker>
                            </b-form-group>
                            <b-form-group label="End Time">
                                <b-form-input v-model="newEvent.endTime" placeholder="HH:MM"></b-form-input>
                            </b-form-group>
                            <hr>
                            <b-button type="submit" block variant="secondary">Save</b-button>
                            <b-button type="button" @click="doDeleteEvent" v-if="newEvent.id" block variant="danger">Delete</b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-modal>
        </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import RepairFullCalendar from '../../components/RepairFullCalendar'
import TeamSearch from '../../components/TeamSearch'

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
    startDate: null,
    endDate: null,
    selectedTeams: [],
    storedTeams: [],

    calendarSearch: null,
    debouncer: null,

    newEvent: {
        id: null,
        name: null,
        description: null,
        colour: null,
        team_id: null,
        team: { id: null },
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
    }
  }),
  components: {
    FriendlyDate,
    ResourceList,
    TeamSearch,
    RepairFullCalendar
  },
  computed: {
    ...mapGetters('repairs', {
        'data': 'calendarData',
        'isDeleting': 'isDeleting',
        'isLoading': 'isCalendarLoading',
        'pagination': 'pagination',
    }),
    ...mapGetters('events', {
        'errors': 'errors',
        'repairEvents': 'data'
    }),
    ...mapGetters('teams', { 'teams': 'data' }),
  },
  created() {
      this.fetchAll().then(response => {
          this.storedTeams = response.data
      })
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
      if(modalId == 'repair-event-modal') {
        this.newEvent.id = null
        this.newEvent.name = null
        this.newEvent.description = null
        this.newEvent.colour = null
        this.newEvent.team_id = null
        this.newEvent.team = { id: null }
        this.newEvent.startDate = null
        this.newEvent.endDate = null
        this.newEvent.startTime = null
        this.newEvent.endTime = null
      }
    })
  },
  methods: {

    ...mapActions('repairs', ['fetchManyCalendar', 'fetchStatuses', 'repairDropped']),
    ...mapActions('teams', ['fetchAll']),
    ...mapActions('events', ['createRepairEvent', 'deleteRepairEvent', 'fetchManyRepairEvents', 'repairEventDropped']),
    
    teamUpdated() {
      this.newEvent.team_id = this.newEvent.team.id;
    },

    viewCreateEvent(payload) {
        this.newEvent.startDate = payload.start
        this.newEvent.endDate = payload.end
        this.newEvent.startTime = this.moment(payload.start).format('HH:mm')
        this.newEvent.endTime = this.moment(payload.end).format('HH:mm')
        this.$bvModal.show('repair-event-modal')
    },
    
    eventClicked(payload) {
        switch(payload.event.extendedProps.type) {
            case 'repair':
                this.$router.push({name: 'dash.repairs.complete', params: { id: payload.event._def.publicId }})
            break;
            case 'event':
                payload = JSON.parse(JSON.stringify(payload))
                this.newEvent = payload.event.extendedProps.event
                this.newEvent.startDate = this.moment(payload.event.extendedProps.event.start, 'YYYY-MM-DD HH:mm:ss').toDate()
                this.newEvent.endDate = this.moment(payload.event.extendedProps.event.end, 'YYYY-MM-DD HH:mm:ss').toDate()
                this.newEvent.startTime = this.moment(payload.event.extendedProps.event.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                this.newEvent.endTime = this.moment(payload.event.extendedProps.event.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                this.$bvModal.show('repair-event-modal')
            break;
        }
    },
    
    eventDropped(payload) {
        switch(payload.event.extendedProps.type) {
            case 'repair':
                this.repairDropped({
                    'repairId': payload.event._def.publicId,
                    'start': this.moment(payload.event.start).format('YYYY-MM-DD HH:mm'),
                    'end': this.moment(payload.event.end).format('YYYY-MM-DD HH:mm'),
                })
            break;
            case 'event':
                this.repairEventDropped({
                    'eventId': payload.event.extendedProps.event.id,
                    'start': this.moment(payload.event.start).format('YYYY-MM-DD HH:mm'),
                    'end': this.moment(payload.event.end).format('YYYY-MM-DD HH:mm'),
                }).then(response => {
                    if(response.data) this.calendarData()
                })
            break;
        }
    },

    doCreateEvent() {
        this.createRepairEvent({
            ...this.newEvent,
            start: this.moment(this.newEvent.startDate).format('DD/MM/YYYY') + ` ${this.newEvent.startTime}`,
            end: this.moment(this.newEvent.endDate).format('DD/MM/YYYY') + ` ${this.newEvent.endTime}`,
        }).then(response => {
            this.calendarData().then(response => {
                this.$bvModal.hide('repair-event-modal')
            })
        });
    },

    doDeleteEvent() {
        this.deleteRepairEvent(this.newEvent).then(response => {
            this.calendarData().then(response => {
                this.$bvModal.hide('repair-event-modal')
            })
        });
    },

    toggleTeamSelect(teamId) {
        if(this.selectedTeams.includes(teamId)) {
            this.selectedTeams.splice(this.selectedTeams.findIndex(team => { return (team == teamId) }), 1)
        }else{
            this.selectedTeams.push(teamId)
        }
        this.calendarData()
    },

    async calendarData (payload) {
        if(payload && payload.start_date && payload.end_date) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        clearTimeout(this.debouncer)
        this.debouncer = setTimeout(() => {
            this.fetchManyCalendar({
                'start_date': this.startDate,
                'end_date': this.endDate,
                'teams': this.selectedTeams,
                'query': this.calendarSearch,
            })
            this.fetchManyRepairEvents({
                'start_date': this.startDate,
                'end_date': this.endDate,
                'teams': this.selectedTeams,
                'query': this.calendarSearch,
            })
        })
    },
  },
}
</script>