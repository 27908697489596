<template>

    <b-row>
        <b-col cols="12">
            <b-row class="scrolling-cards row flex-row flex-nowrap">
                <b-col cols="12" md="4" v-for="ticketStatus in ticketStatuses" :key="ticketStatus">
                    <b-card no-body class="bg-light">
                        <b-row class="h-100">
                            <b-col cols="12" class="h-100">

                                <b-row class="px-3 pt-3">
                                    <b-col cols="12 d-flex align-items-center">
                                        <p class="h5 mb-0 text-truncate">{{ ticketStatus }}</p>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr class="mb-0">
                                    </b-col>
                                </b-row>

                                <b-row class="p-1 scrollable-column">
                                    <b-col cols="12">
                                        <b-list-group>
                                            <draggable :emptyInsertThreshold="200" :component-data="{ticketStatus}" v-model="lists[ticketStatus]" v-bind="dragOptions" @change="onChange($event, ticketStatus)" @start="dragging = true" @end="dragging = false">
                                                <transition-group>
                                                    <b-list-group-item button v-for="ticket in lists[ticketStatus]" @click="viewTicket(ticket)" :key="`ticket${ticket.id}`">
                                                        <div class="d-flex flex-column flex-fill">
                                                            <div class="d-flex flex-row flex-fill">
                                                                <p class="h5 flex-fill mb-0 text-primary">#{{ ticket.id }} {{ ticket.name }}</p>
                                                            </div>
                                                            <div class="d-flex flex-column flex-fill mb-0 mb-md-1">
                                                                <p class="mb-0" v-if="ticket.ticket_address">Postcode: {{ ticket.ticket_address.postcode }}</p>
                                                                <p v-if="ticket.latest_note" class="mt-1 mb-0 text-muted">Latest Note: {{ ticket.latest_note.note }}</p>

                                                                <b-badge class="mt-2" variant="primary" v-if="ticket.next_repair">Repair Scheduled {{ moment(ticket.next_repair.repair_date).format('DD/MM/YYYY') }}</b-badge>
                                                                <small class="mt-2 text-muted font-italic">Ticket created <FriendlyDate :date="ticket.created_at" /></small>
                                                            </div>
                                                        </div>
                                                    </b-list-group-item>
                                                </transition-group>
                                            </draggable>
                                        </b-list-group>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</template>

<script>
/* eslint-disable */
import FriendlyDate from './FriendlyDate'
import draggable from 'vuedraggable'
import momentMixin from '../mixins/momentMixin'

export default {
    components: { FriendlyDate, draggable },
    mixins: [momentMixin],
    created() { this.getTicketsWithStatus() },
    data () {
        return {
            moveTimeout: null,
            dragging: false,
            lists: {},
        }
    },
    props: {
        data: {
            required: true,
            type: Array
        },
        isDeleting: {
            default: false,
            type: Boolean
        },
        isLoading: {
            default: false,
            type: Boolean
        },
        searchQuery: {
            default: '',
            type: String
        },
        ticketStatuses: Array
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: "ontouchend" in document,
                ghostClass: "ghost"
            };
        },
    },
    methods: {
        toggleCollapse (name) {
            this.collapsed[name] = !this.collapsed[name]
        },

        // Draggable Events
        onChange($event, ticketStatus) {
            if(this.lists[ticketStatus].length) {
                window.axios.post('/tickets/cards/statuses/update', {
                    'tickets': this.lists[ticketStatus],
                    'status': ticketStatus
                }).then(response => {
                    
                })
            }
        },
        
        getTicketsWithStatus() {
            window.axios.get('/tickets-with-statuses').then(response => {
                Object.keys(response.data).forEach(objKey => {
                    this.$set(this.lists, objKey, response.data[objKey].map(obj => { return obj; }));
                })
                this.ticketStatuses.forEach(ticketStatus => {
                    if(!Object.keys(this.lists).includes(ticketStatus)) this.$set(this.lists, ticketStatus, [])
                })
            })
        },

        viewTicket(ticket) {
            if(!this.dragging) {
                this.$router.push({ name: 'dash.tickets.view', params: {id: ticket.id } })
            }
        }
    }
}
</script>

<style>
    .scrollable-column {
        overflow-y: scroll;
        height: calc(100% - 57px);
    }
    .ghost {
        background: #4BB5A2;
    }
    .scrolling-cards{
        overflow-x: auto;
    }
    .scrolling-cards .card {
        height: calc(100vh - 13rem);
    }
</style>