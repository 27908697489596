<template>

    <b-row class="h-100">
        <b-col cols="12" class="d-flex flex-column flex-fill">
            <FullCalendar class="d-flex flex-column flex-fill" ref="calendar" :options="calendarOptions">
                <template #eventContent="arg">
                    <div v-if="arg.event._def.extendedProps.type == 'repair'" :id="`repair-event-${arg.event._def.publicId}`">
                        <div class="fc-event-time">{{ moment(arg.event._instance.range.start).format('HH:mm') }} - {{ moment(arg.event._instance.range.end).format('HH:mm') }}</div>
                        <div class="fc-event-title">{{ arg.event._def.title }}</div>
                        <b-tooltip placement="top" :target="`repair-event-${arg.event._def.publicId}`">
                            <div class="d-flex flex-column align-items-start m-2">
                                <div class="mb-2">
                                    <h5 class="text-left mb-0">Repair #{{ arg.event._def.extendedProps.repair.id }}</h5>
                                    <p class="text-left mb-0">#{{ arg.event._def.extendedProps.repair.ticket.customer.id }} {{ arg.event._def.extendedProps.repair.ticket.customer.forename }} {{ arg.event._def.extendedProps.repair.ticket.customer.surname }}</p>
                                </div>
                                <div>
                                    <h5>Ticket Address</h5>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.repair.ticket.ticket_address.address_line_1 }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.repair.ticket.ticket_address.address_line_2 }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.repair.ticket.ticket_address.city }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.repair.ticket.ticket_address.county }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.repair.ticket.ticket_address.postcode }}</p>
                                </div>
                            </div>
                        </b-tooltip>
                    </div>
                    <div v-else>
                        <div class="fc-event-time">{{ moment(arg.event._instance.range.start).format('HH:mm') }} - {{ moment(arg.event._instance.range.end).format('HH:mm') }}</div>
                        <div class="fc-event-title">{{ arg.event._def.title }}</div>
                    </div>
                </template>
            </FullCalendar>
        </b-col>
    </b-row>

</template>

<script>
/* eslint-disable */
import moment from 'moment'
import FriendlyDate from './FriendlyDate'
import momentMixin from '../mixins/momentMixin'

// Calendar.
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
    components: { FriendlyDate, FullCalendar },
    mixins: [momentMixin],
    data () {
        return {
            calendarOptions: {
                locale: 'gb',
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin
                ],
                headerToolbar: { center: 'dayGridMonth,timeGridWeek,timeGridDay' },
                customButtons: {
                    today: {
                        text: 'today',
                        click: () => {
                            this.getData('today')
                        }
                    },
                    next: {
                        click: () => {
                            this.getData('next')
                        }
                    },
                    prev: {
                        click: () => {
                            this.getData('prev')
                        }
                    }
                },
                editable: true,
                selectable: this.selectable,
                select: (info) => { this.$emit('select', info) },
                dateClick: (info) => { this.$emit('dateClick', info) },
                eventClick: (info) => { this.$emit('eventClick', info) },
                eventDrop: (info) => { this.$emit('eventDrop', info) },
                eventResize: (info) => { this.$emit('eventResize', info) },
                firstDay: 1,
                allDaySlot: false,
                initialView: 'timeGridWeek',
                events: [],
            }
        }
    },
    mounted() {
        if(this.date == null) { 
            if(localStorage.getItem('repair-full-calendar-date')) {
                this.date = this.moment(localStorage.getItem('repair-full-calendar-date')).toDate()
            }else{
                this.date = this.moment().toDate()
            }
        }
        window.requestAnimationFrame(() => {
            this.$refs.calendar.getApi().gotoDate(this.date)
            this.getData()
        })
    },
    props: {
        date: {
            required: false,
            default: null,
        },
        data: {
            required: true,
            type: Array,
            default: () => ([])
        },
        repairEvents: {
            required: false,
            type: Array,
            default: () => ([])
        },
        isDeleting: {
            default: false,
            type: Boolean
        },
        isLoading: {
            default: false,
            type: Boolean
        },
        // Calendar Props
        selectable: { default: false, type: Boolean },
    },
    computed: {
        calendarEvents() {
            var calendarEvents = []

            this.data.forEach(event => {
                calendarEvents.push({
                    'id': event.id,
                    'title': '#' + event.id + ' ' + (event.team_id ? (event.team.initials ? event.team.initials + ' ' : '' ) : '') + event.ticket.ticket_address.postcode + ' - ' + event.ticket.name,
                    'start': this.moment(event.repair_date.split(' ')[0] + ' ' + event.repair_time, 'YYYY-MM-DD HH:mm:ss').toDate(),
                    'end': this.moment(event.repair_date.split(' ')[0] + ' ' + event.repair_time, 'YYYY-MM-DD HH:mm:ss').add(event.duration, 'minutes').toDate(),
                    'backgroundColor': '#2E3145',
                    'borderColor': '#2E3145',
                    'extendedProps': {
                        'type': 'repair',
                        'repair': event
                    }
                })
            })

            this.repairEvents.forEach(event => {
                calendarEvents.push({
                    'id': event.id,
                    'title': (event.team_id ? (event.team.initials ? event.team.initials + ' ' : '' ) : '') + event.name,
                    'start': this.moment(event.start, 'YYYY-MM-DD HH:mm:ss').toDate(),
                    'end': this.moment(event.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
                    'backgroundColor': event.colour ?? '#4BB5A2',
                    'borderColor': event.colour ?? '#4BB5A2',
                    'extendedProps': {
                        'type': 'event',
                        'event': event
                    }
                })
            })

            return calendarEvents
        },
    },
    methods: {
        getData(method) {
            if(method) this.$refs.calendar.getApi()[method]();
            this.$emit('getData', {
                'start_date': this.moment(this.$refs.calendar.getApi().getDate()).subtract(6, 'days').format('DD/MM/YYYY'),
                'end_date': this.moment(this.$refs.calendar.getApi().getDate()).add(20, 'days').format('DD/MM/YYYY')
            })
            localStorage.setItem('repair-full-calendar-date', this.$refs.calendar.getApi().getDate())
        }
    },
    watch: {
        date(date) {
            if(date) {
                this.$refs.calendar.getApi().gotoDate(date)
            }
        },
        data(data) {
            this.calendarOptions.events = this.calendarEvents
        },
        repairEvents(data) {
            this.calendarOptions.events = this.calendarEvents
        }
    }
}
</script>

<style>

</style>