<template>
  <b-container fluid>
    <RepairResourceList
      resource="repair" title="Repairs"
      :data="data" :calendar-data="calendarData" :custom-calendar-data="repairEvents" :is-deleting="isDeleting" :is-loading="isLoading" :is-calendar-loading="isCalendarLoading" :pagination="pagination"
      :search-query="$route.query.query"
      @paginate="onPaginate" @calendar="onCalendar" @map="onMap"
      @getCalendarData="onCalendar"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{ name: 'dash.repairs.complete', params: { id: item.id } }">{{ item.ticket.name }}</router-link></p>
            <p class="align-self-end mb-1 text-muted">Scheduled for {{ moment(item.repair_date).format('DD/MM/YYYY') }} @ {{ item.repair_time }}</p>

          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0 flex-fill">Postcode: {{ item.ticket.ticket_address.postcode }}</p>
          </div>
          <div class="d-none d-md-flex flex-row mt-1">
            <p class="align-self-end mb-0 text-muted"><b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this order.'"  :to="{name: 'dash.repairs.complete', params: { id: item.id }}"><b-icon icon="tools" />  Complete Repair</b-button></p>
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'View repair details.'" :to="{ name: 'dash.tickets.view', params: { id: item.ticket.id } }"><b-icon icon="person" /> View Ticket</b-button>
          </div>
        </div>
      </template>

    </RepairResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import RepairResourceList from '../../components/RepairResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser,momentMixin],
  created () {
    this.fetchMany({
        ...this.$route.query,
        'path': 1,
        'type': 'repairs',
    })
  },
  data: () => ({
      // Calendar Data
      startDate: null,
      endDate: null,
  }),
  components: {
    FriendlyDate,
    RepairResourceList,
  },
  computed: {
    ...mapGetters('repairs', ['data', 'calendarData', 'isDeleting', 'isLoading', 'isCalendarLoading', 'pagination']),
    ...mapGetters('events', {
        'errors': 'errors',
        'repairEvents': 'data'
    }),
  },
  methods: {
    ...mapActions('repairs', ['batchDelete', 'fetchMany', 'fetchManyCalendar', 'fetchMap']),
    ...mapActions('events', ['fetchManyRepairEvents']),

    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page, type: 'repairs' })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query, type: 'repairs' })
      }
    },
    async onCalendar (payload) {
        if(payload) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }else{
          if(!this.startDate) this.startDate = this.moment().subtract(6, 'days').format('DD/MM/YYYY')
          if(!this.endDate) this.endDate = this.moment().add(13, 'days').format('DD/MM/YYYY')
        }
        
        this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate,
        })
        this.fetchManyRepairEvents({
            'start_date': this.startDate,
            'end_date': this.endDate,
        })
    },
    async onMap () {
      await this.fetchMap()
    },
  }
}
</script>

<style>

</style>
