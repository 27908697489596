export const TICKETS_BATCH_DELETE_REQUEST = 'TICKETS_BATCH_DELETE_REQUEST'
export const TICKETS_BATCH_DELETE_SUCCESS = 'TICKETS_BATCH_DELETE_SUCCESS'
export const TICKETS_BATCH_DELETE_FAILURE = 'TICKETS_BATCH_DELETE_FAILURE'

export const TICKETS_FETCH_MANY_REQUEST = 'TICKETS_FETCH_MANY_REQUEST'
export const TICKETS_FETCH_MANY_SUCCESS = 'TICKETS_FETCH_MANY_SUCCESS'
export const TICKETS_FETCH_MANY_FAILURE = 'TICKETS_FETCH_MANY_FAILURE'

export const TICKETS_FETCH_ALL_REQUEST = 'TICKETS_FETCH_ALL_REQUEST'
export const TICKETS_FETCH_ALL_SUCCESS = 'TICKETS_FETCH_ALL_SUCCESS'
export const TICKETS_FETCH_ALL_FAILURE = 'TICKETS_FETCH_ALL_FAILURE'

export const TICKETS_FETCH_REQUEST = 'TICKETS_FETCH_REQUEST'
export const TICKETS_FETCH_SUCCESS = 'TICKETS_FETCH_SUCCESS'
export const TICKETS_FETCH_FAILURE = 'TICKETS_FETCH_FAILURE'

export const TICKETS_SAVE_REQUEST = 'TICKETS_SAVE_REQUEST'
export const TICKETS_SAVE_SUCCESS = 'TICKETS_SAVE_SUCCESS'
export const TICKETS_SAVE_FAILURE = 'TICKETS_SAVE_FAILURE'

export const TICKETS_PROCESS_REQUEST = 'TICKETS_PROCESS_REQUEST'
export const TICKETS_PROCESS_SUCCESS = 'TICKETS_PROCESS_SUCCESS'
export const TICKETS_PROCESS_FAILURE = 'TICKETS_PROCESS_FAILURE'

export const EMPTY_TICKETS = 'EMPTY_TICKETS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'