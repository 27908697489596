<template>
    <b-row>
      <b-col cols="12">
          <GmapMap
            :center="{ lat:Number(currentTicket.ticket_address.latitude), lng: Number(currentTicket.ticket_address.longitude) }"
            :zoom="10"
            @click="mapClicked"
            :options="{
              streetViewControl: false,
              disableDefaultUi: true,
              fullscreenControl: false,
              mapTypeContro: false,
            }"
            map-type-id="terrain"
            style="width: 100%; height: calc(100vh - 11.5rem)"
          >
            <GmapInfoWindow
                :options="{
                    content: '<span>You Are Here</em></span>',
                    pixelOffset: {
                        width: 0,
                        height: -35,
                    }
                }"
                :opened="true"
                v-if="youAreHere"
                :position="{ lat:Number(currentTicket.ticket_address.latitude), lng: Number(currentTicket.ticket_address.longitude) }"/>
            <GmapMarker
              :key="`current-repair-ticket`"
              @click="selectYouAreHere"
              icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
              :position="{ lat:Number(currentTicket.ticket_address.latitude), lng: Number(currentTicket.ticket_address.longitude) }"
            />
            <GmapInfoWindow
                :options="{
                    content: '<span>'+ moment(selectedRepair.repair_date).format('DD/MM/YYYY') +'</em></span>',
                    pixelOffset: {
                        width: 0,
                        height: -35,
                    }
                }"
                :opened="true"
                v-if="selectedRepair"
                :position="{ lat:Number(selectedRepair.ticket.ticket_address.latitude), lng: Number(selectedRepair.ticket.ticket_address.longitude) }"/>
            <GmapMarker
              :key="`marker${index}`"
              icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
              v-for="(repair, index) in computedRepairs"
              @click="selectRepair(repair)"
              :position="{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }"
            />
          </GmapMap>
      </b-col>
    </b-row>
</template>

<script>
import currentUser from '../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [currentUser, momentMixin],
  props: {
        currentTicket: {
            required: true,
        },
        data: {
            required: true,
            type: Array
        },
        showMap: Boolean,
  },
  data: () => ({
      youAreHere: false,
      selectedRepair: null,
  }),
  computed: {
    computedRepairs() {
        return this.data.filter(repair => {
            return ( repair.ticket.ticket_address && ( !isNaN(repair.ticket.ticket_address.latitude) && !isNaN(repair.ticket.ticket_address.longitude) ))
        })
    },
  },
  methods: {
      mapClicked() {
        this.youAreHere = false
        this.selectedRepair = null
      },

      selectRepair(repair) {
          this.selectedRepair = repair
          this.youAreHere = false
      },

      selectYouAreHere() {
          this.youAreHere = true
          this.selectedRepair = null
      },
  }
}
</script>

<style lang="scss">

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }
</style>
