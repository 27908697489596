import {
  TICKETS_BATCH_DELETE_REQUEST,
  TICKETS_BATCH_DELETE_SUCCESS,
  TICKETS_BATCH_DELETE_FAILURE,
  TICKETS_FETCH_MANY_REQUEST,
  TICKETS_FETCH_MANY_SUCCESS,
  TICKETS_FETCH_MANY_FAILURE,
  TICKETS_FETCH_ALL_REQUEST,
  TICKETS_FETCH_ALL_SUCCESS,
  TICKETS_FETCH_ALL_FAILURE,
  TICKETS_FETCH_REQUEST,
  TICKETS_FETCH_SUCCESS,
  TICKETS_FETCH_FAILURE,
  TICKETS_SAVE_REQUEST,
  TICKETS_SAVE_SUCCESS,
  TICKETS_SAVE_FAILURE,
  TICKETS_PROCESS_REQUEST,
  TICKETS_PROCESS_SUCCESS,
  TICKETS_PROCESS_FAILURE,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(TICKETS_BATCH_DELETE_REQUEST)
    return window.axios.post('/tickets', { _method: 'DELETE', ids }).then(response => {
      commit(TICKETS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TICKETS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, ticketStatus = 'All' }) {
    commit(TICKETS_FETCH_MANY_REQUEST)
    return window.axios.get('/tickets', { params: { query, page, ticketStatus } }).then(response => {
      commit(TICKETS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TICKETS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchAll ({ commit }) {
    commit(TICKETS_FETCH_ALL_REQUEST)
    return window.axios.get('/tickets/all').then(response => {
      commit(TICKETS_FETCH_ALL_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TICKETS_FETCH_ALL_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(TICKETS_FETCH_REQUEST)
    return window.axios.get(`/tickets/${id}`).then(response => {
      commit(TICKETS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TICKETS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, {ticketId, ticket}) {
    const method = 'post'//ticketId ? 'put' : 'post'
    const path = ticketId ? `/tickets/${ticketId}?_method=PUT` : '/tickets'

    commit(TICKETS_SAVE_REQUEST)
    return window.axios[method](path, ticket, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      commit(TICKETS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(TICKETS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(TICKETS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  // process ({ commit }, {ticketId, ticket}) {
  //   const method = 'post'
  //   const path = `/tickets/process/${ticketId}`

  //   commit(TICKETS_PROCESS_REQUEST)
  //   return window.axios[method](path, ticket).then(response => {
  //     commit(TICKETS_PROCESS_SUCCESS, response)
  //     return response
  //   }).catch(error => {
  //     if (error.response.status === 422) {
  //       commit(TICKETS_PROCESS_FAILURE, { validationErrors: error.response.data })
  //     } else {
  //       commit(TICKETS_PROCESS_FAILURE, { error })
  //     }
  //     throw error
  //   })
  // },
  empty({ commit }) {
    commit(EMPTY_TICKETS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  },

  // Non Crud Functions
  fetchStatuses() {
    return window.axios.get('/ticket-statuses');
  }
}
