<template>
  <div>
    <b-row class="mb-2" id="header">
      <b-col class="mb-2 mb-md-0" cols="12" :md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Tickets</h1>
        <p class="mb-2">
            <small v-if="displayList"><b-icon icon="question-circle" v-if="isLoading" />{{ isLoading ? '' : pagination.total }} {{ pagination.total === 1 ? 'ticket' : 'tickets' }} found</small>
            <small v-if="displayCards">Card View</small>
            <small v-if="displayMap">Map View</small>
        </p>
      </b-col>
      <b-col class="d-md-none mb-2" cols="12">
        <b-input class="mr-2" placeholder="Search..." @update="onSearch"></b-input>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8">
        <b-input class="mr-2 d-none d-md-block" placeholder="Search..." @update="onSearch"></b-input>
        <b-button class="mr-2 text-nowrap" variant="secondary" :to="{name: 'dash.tickets.create'}" v-if="!selected.length">Create Ticket</b-button>
        <b-button class="mr-2 text-nowrap" :disabled="!data.length" variant="dark" @click.prevent="selectAll" v-if="selected.length"><span v-if="selected.length < data.length || !data.length">Select All</span><span v-if="selected.length === data.length && data.length">Deselect All</span></b-button>
        <b-button class="mr-2 text-nowrap" variant="danger" v-b-modal.delete-many-resources v-if="selected.length">Delete Ticket{{ selected.length > 1 ? 's' : '' }}</b-button>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayCards = true"><fa-icon :icon="'columns'"/></b-button>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayList = true"><fa-icon :icon="'list'"/></b-button>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayMap = true"><fa-icon :icon="'map-marked'"/></b-button>

        <b-dropdown class="ml-auto ml-md-0" no-caret right variant="dark">
          <template #button-content>
            <b-icon font-scale="1.2" icon="three-dots-vertical"></b-icon>
          </template>

          <b-dd-item variant="secondary" v-if="selected.length" :to="{name: 'dash.tickets.create'}">Create Ticket</b-dd-item>
          <b-dd-divider v-if="selected.length" />
          <b-dd-item-button @click.prevent="selectAll" :disabled="!data.length || !this.displayList" v-if="!selected.length"><span v-if="selected.length < data.length || !data.length">Select All</span><span v-if="selected.length === data.length && data.length">Deselect All</span></b-dd-item-button>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row id="loading-wheel" class="mt-5 mb-2" v-if="isLoading">
      <b-col class="text-center" cols="12">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
    </b-row>

    <div id="card-view" v-if="displayCards && !isLoading">
        <TicketCards
            :data="data"
            :ticket-statuses="ticketStatuses"
        /> 
    </div>

    <!-- V-show to keep the map in the dom, as it expects it to be there -->
    <div id="map-view" v-show="displayMap && !isLoading">
        <TicketMap ref="ticketMap" @ticketClicked="ticketClicked"/> 
    </div>

    <div id="list-view" v-if="displayList && !isLoading">
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('filters')" role="button">
                            <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['filters'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                            <div class="d-flex flex-column flex-fill ml-4">
                                <p class="h4 font-weight-bold mb-0">Filters</p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4" v-if="!collapsed['filters']">
                        <b-col cols="12">
                            <slot name="filters" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="!data.length && !isLoading">
            <b-col class="text-center" cols="12">
                <b-card class="py-5 text-center">
                    <p class="mb-4"><b-icon class="display-1 text-dark" icon="cloud-arrow-down"></b-icon></p>
                    <p class="font-weight-normal h5 mb-0 text-dark">Could not find any tickets that match the given criteria.</p>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="data && !isLoading">
            <b-col cols="12">
                <b-list-group>
                    <b-list-group-item :key="item.id" v-for="item in data">
                        <div class="d-flex flex-row justify-items-start">
                            <div class="align-self-center mr-2">
                                <b-checkbox :value="item.id" v-model="selected"></b-checkbox>
                            </div>
                            <slot name="listItem" :item="item" />
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="data && !isLoading && pagination.per < pagination.total">
            <b-col cols="12">
                <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
            </b-col>
        </b-row>
    </div>

    <b-modal centered id="delete-many-resources" :title="`Delete ${this.selected.length} ticket`+ (this.selected.length > 1 ? 's' : '') +`?`"
      ok-variant="danger" cancel-variant="dark" ok-title="Delete" cancel-title="Cancel" @ok.prevent="onDeleteModalOk"
      :ok-disabled="isDeleting" :cancel-disabled="isDeleting">
      <p class="text-center"><b-spinner class="my-2" variant="danger" v-if="isDeleting"/></p>
      <p v-if="!isDeleting && !softDeletes">This action is destructive, which means the data will be permanently deleted.</p>
      <p v-if="!isDeleting && softDeletes">They will be marked as "trashed".</p>
    </b-modal>



    <right-panel-overlay v-model="showTicketOverlay">
        <b-row v-if="ticketSelected">
            <b-col class="mt-5">
                <div class="d-flex flex-column align-items-center">
                    <h4 class="text-primary font-weight-bold">#{{ ticketSelected.id }} {{ ticketSelected.name }}</h4>
                    <small class="text-muted">{{ ticketSelected.status }}</small>
                    <small class="text-muted" v-if="ticketSelected.email">{{ ticketSelected.email }}</small>
                    <small class="text-muted" v-if="ticketSelected.phone">{{ ticketSelected.phone }}</small>
                    <b-button size="sm" v-b-popover.hover.bottom="'View Ticket'" class="m-1 icon-button d-flex align-items-center justify-content-center" :to="{name: 'dash.tickets.view', params: { id: ticketSelected.id }}">
                        View Ticket
                    </b-button>
                </div>
                <div class="mx-4">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center" v-if="ticketSelected.ticket_address">
                    <h6 class="text-primary mb-1">Ticket Address</h6>
                    <small class="text-muted" v-if="ticketSelected.ticket_address.address_line_1">{{ ticketSelected.ticket_address.address_line_1 }}</small>
                    <small class="text-muted" v-if="ticketSelected.ticket_address.address_line_2">{{ ticketSelected.ticket_address.address_line_2 }}</small>
                    <small class="text-muted" v-if="ticketSelected.ticket_address.city">{{ ticketSelected.ticket_address.city }}</small>
                    <small class="text-muted" v-if="ticketSelected.ticket_address.county">{{ ticketSelected.ticket_address.county }}</small>
                    <small class="text-muted" v-if="ticketSelected.ticket_address.postcode">{{ ticketSelected.ticket_address.postcode }}</small>
                </div>
                <div class="mx-4">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center mb-3">
                    <h6 class="text-primary mb-1">Ticket Notes</h6>
                    <div v-if="ticketSelected.notes.length" class="w-100">
                        <b-card v-for="(note, noteInd) in ticketSelected.notes" :key="`note-${noteInd}`" no-body class="mx-1 p-2 mt-1">
                            <p class="white-space-pre m-1">{{ note.note }}</p>
                            <hr class="mt-0 mb-0">
                            <small class="text-muted m-1">Left By: {{ note.user.name }}</small>
                        </b-card>
                    </div>
                    <div v-else class="w-100">
                        <b-card no-body class="mx-1 p-2 mt-3">
                            <p class="text-center mb-0">There are no notes.</p>
                        </b-card>
                    </div>
                </div>
            </b-col>
        </b-row>
    </right-panel-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import TicketCards from '../components/TicketCards'
import TicketMap from '../components/TicketMap'
import RightPanelOverlay from './RightPanelOverlay'

export default {
  components: { TicketCards, TicketMap, RightPanelOverlay },
  data () {
    return {
      selected: [],

      collapsed: {
        filters: true,
      },

      displayCards: true, 
      displayList: false,
      displayMap: false,

      showTicketOverlay: false,
      ticketSelected: {
        id: null,
        name: null,
        description: null,
        status: null,
        phone: null,
        email: null,
        ticket_address: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          county: null,
          postcode: null,
        },
        notes: [],
      },
    }
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    isDeleting: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    pagination: {
      required: true,
      type: Object
    },
    softDeletes: {
      default: false,
      type: Boolean
    },
    searchQuery: {
      default: '',
      type: String
    },
    ticketStatuses: {
        required: true,
    }
  },
  methods: {
    onSearch (query) {
      if(!this.displayList){
        this.displayList = true;
      }
      this.onPaginate(1)
      this.$emit('search', query)
    },
    onPaginate (pageNo) {
      this.$emit('paginate', pageNo)
    },
    onDeleteModalOk (bvModalEvt) {
      this.$emit('delete-many', { ids: this.selected, modalId: bvModalEvt.target.id })
    },
    selectAll () {
      if (this.selected.length === this.data.length) {
        this.selected = []
      } else {
        this.selected = this.data.map(d => d.id)
      }
    },
    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    ticketClicked(ticket) {
      console.log({ticket})
      this.ticketSelected = ticket
      this.showTicketOverlay = true
    }
  },
  watch: {
    data () {
      this.selected = []
    },
    displayCards () {
        if(this.displayCards) {
            this.displayList = false
            this.displayMap = false
        }
    },
    displayList () {
        if(this.displayList) {
            this.displayCards = false
            this.displayMap = false
            this.onPaginate(1)
            this.$emit('search', query)
        }
    },
    displayMap () {
        if(this.displayMap) {
            this.displayCards = false
            this.displayList = false
            this.$refs.ticketMap.loadData()
        }
    },
    showTicketOverlay(val) {
      if(!val) this.ticketSelected = {
        id: null,
        name: null,
        description: null,
        status: null,
        phone: null,
        email: null,
        ticket_address: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          county: null,
          postcode: null,
        },
        notes: [],
      }
    }
  }
}
</script>

<style>

</style>