<template>
  <div>
    <b-row class="mb-2" id="header">
      <b-col class="mb-2 mb-md-0" cols="12" :md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Repairs</h1>
        <p class="mb-2">
            <small v-if="displayList"><b-icon icon="question-circle" v-if="isLoading" />{{ isLoading ? '' : pagination.total }} {{ pagination.total === 1 ? 'repair' : 'repairs' }} found</small>
            <small v-if="displayCalendar">Calendar View</small>
            <small v-if="displayMap">Map View</small>
        </p>
      </b-col>
      <b-col class="d-md-none mb-2" cols="12">
        <b-input class="mr-2" placeholder="Search..." @update="onSearch"></b-input>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8">
        <b-input class="mr-2 d-none d-md-block" placeholder="Search..." @update="onSearch"></b-input>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayList = true"><fa-icon :icon="'list'"/></b-button>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayCalendar = true"><b-icon :icon="'calendar-event'"/></b-button>
        <b-button class="mr-2 text-nowrap" variant="primary" @click.prevent="displayMap = true"><fa-icon :icon="'map-marked'"/></b-button>
      </b-col>
    </b-row>

    <b-row id="loading-wheel" class="mt-5 mb-2" v-if="isLoading">
      <b-col class="text-center" cols="12">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
    </b-row>

    <div id="card-view" v-if="displayCalendar">
        <RepairFullCalendar
            :data="calendarData"
            :repairEvents="customCalendarData"
            :is-loading="isCalendarLoading"
            @getData="getCalendarData"
        /> 
    </div>

    <!-- V-show to keep the map in the dom, as it expects it to be there -->
    <div id="map-view" v-show="displayMap && !isLoading">
        <RepairMap
            :data="data"
        />
    </div>

    <div id="list-view" v-if="displayList && !isLoading">
        <b-row class="mb-2" v-if="!data.length && !isLoading">
            <b-col class="text-center" cols="12">
                <b-card class="py-5 text-center">
                    <p class="mb-4"><b-icon class="display-1 text-dark" icon="cloud-arrow-down"></b-icon></p>
                    <p class="font-weight-normal h5 mb-0 text-dark">Could not find any repairs that match the given criteria.</p>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="data && !isLoading">
            <b-col cols="12">
                <b-list-group>
                    <b-list-group-item :key="item.id" v-for="item in data">
                        <div class="d-flex flex-row justify-items-start">
                            <div class="align-self-center mr-2">
                                <b-checkbox :value="item.id" v-model="selected"></b-checkbox>
                            </div>
                            <slot name="listItem" :item="item" />
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="data && !isLoading && pagination.per < pagination.total">
            <b-col cols="12">
                <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
            </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import RepairFullCalendar from '../components/RepairFullCalendar'
import RepairMap from '../components/RepairMap'

export default {
  components: { RepairFullCalendar, RepairMap },
  data () {
    return {
      selected: [],

      collapsed: {
        filters: true,
      },

      displayList: true,
      displayCalendar: false, 
      displayMap: false,
    }
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    calendarData: {
      required: true,
      type: Array
    },
    customCalendarData: {
      required: true,
      type: Array
    },
    isDeleting: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    isCalendarLoading: {
      default: false,
      type: Boolean
    },
    pagination: {
      required: true,
      type: Object
    },
    searchQuery: {
      default: '',
      type: String
    },
  },
  methods: {
    onSearch (query) {
      if(!this.displayList){
        this.displayList = true;
      }
      this.onPaginate(1)
      this.$emit('search', query)
    },
    onPaginate (pageNo) {
      this.$emit('paginate', pageNo)
    },
    onListView (pageNo) {
      this.onPaginate(1)
    },
    onCalendarView () {
        this.$emit('calendar')
    },
    getCalendarData(payload) {
      if(payload) {
          this.startDate = payload.start_date
          this.endDate = payload.end_date
      }
      this.$emit('getCalendarData', payload)
    },
    onMapView () {
        this.$emit('map')
    },
    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },
  },
  watch: {
    data () {
      this.selected = []
    },
    displayCalendar () {
        if(this.displayCalendar) {
            this.displayList = false
            this.displayMap = false
            this.onCalendarView()
        }
    },
    displayList () {
        if(this.displayList) {
            this.displayCalendar = false
            this.displayMap = false
            this.onListView()
        }
    },
    displayMap () {
        if(this.displayMap) {
            this.displayCalendar = false
            this.displayList = false
            this.onMapView()
        }
    },
  }
}
</script>

<style>

</style>
