<template>
  <b-container fluid>
    <ResourceList
      create-to="dash.emails.compose" resource="email" title="Email Feed" :has-buttons="false"
      :data="data" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @paginate="onPaginate" has-filters
      create-text="Compose Email"
      :can-select-many="false"
      @search="onSearch">

      <template #filters>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Inbox Type" class="mb-0">
              <b-form-select v-model="inboxType" @change="onFilterChange">
                <b-form-select-option value="All">All</b-form-select-option>
                <b-form-select-option value="My Inbox">My Inbox</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill mt-3 ml-3 mb-0 mb-md-3">
          
          <div class="d-flex flex-row">
            <div class="flex-row flex-fill" role="button" @click="toggleSelected(item)">
              <h5>{{ item.subject }}</h5>
              <div class="d-flex flex-row">
                <h6 class="mr-3">From: <small class="text-muted">{{ item.from_email }}</small></h6>
                <h6>To: <small class="text-muted">{{ item.to_email }}</small></h6>
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <div class="ml-3 mr-3">
                <small class="text-right mb-0"><span class="text-muted">{{ moment(item.date).format('DD/MM/YYYY @ HH:MM') }}</span></small>
              </div>
              <b-button @click="replyingToEmail = item.id" variant="link" class="mr-3 no-btn-shadow">Reply</b-button>
            </div>
          </div>

          <b-collapse :visible="( item.id == viewEmail )">
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                      <div class="m-3">
                        <iframe :ref="`iframe${item.id}`" v-if="!isLoading" :srcdoc="item.body" height="500" frameborder="0" class="w-100"></iframe>
                      </div>
                  </b-col>
                </b-row>
                <b-row v-if="item.attachments.length">
                  <b-col cols="12">
                      <div class="mb-3 ml-3 mr-3">
                        <p>Attachments:</p>
                        <div class="d-flex flex-row flex-fill flex-wrap">
                          <a target="_blank" :href="`/attachment/${attachment.id}`" class="btn btn-outline-secondary mr-2 m2" v-for="(attachment, attachmentInd) in item.attachments" :key="`attachment${attachmentInd}`"><fa-icon icon="download" /> {{ attachment.name }}</a>
                        </div>
                      </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </template>

    </ResourceList>

    <b-modal id="email-modal" centered vertical size="lg" @ok="queueEmail">
      <template #modal-header="{ cancel }">
        <div class="container-fluid mt-3 mb-3">
          <b-row>
            <b-col cols="7" class="flex flex-column flex-fill align-items-center justify-content-center">
              <h4>Schedule Reply</h4>
              <small v-if="replyingToEmail">To: {{ data[computedReplyingToIndex].from.forename }} {{ data[computedReplyingToIndex].from.surname }} ({{ data[computedReplyingToIndex].from.email }})</small>
            </b-col>
            <b-col cols="5" class="d-flex flex-row flex-fill align-items-start justify-content-end">
              <b-button variant="danger" @click="cancel"><fa-icon icon="times" /></b-button>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #default>
        <div class="container-fluid">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-input placeholder="Subject" v-model="newEmailSubject"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="h3 font-weight-bold text-muted mb-0">Schedule Email</p>
                      </div>
                      <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                        <b-form-checkbox v-model="setEmailSchedule"></b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="setEmailSchedule">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group class="mb-0">
                      <b-form-datepicker placeholder="Scheduled Date" v-model="newEmailScheduledDateAt"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" class="mt-3 md-0">
                    <b-form-group class="mb-0">
                      <b-input placeholder="Scheduled Time (HH:MM)" v-model="newEmailScheduledTimeAt"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted mb-0">Template</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <template-search
                      :error-feed="errors.errors"
                      :error-key="'template_id'"
                      class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                      type="template"
                      @input="templateUpdated"
                      v-model="template"
                    />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <Quill :new-content="templateContent" v-model="newEmailContent"/>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button">
                    <div class="container-fluid">
                      <b-row>
                        <b-col md="7" class="d-flex flex-row" @click.prevent="toggleCollapse('email_attachments')">
                          <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['email_attachments'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                          <div class="d-flex flex-column flex-fill ml-4 justify-content-center">
                            <p class="h4 font-weight-bold mb-0">Attachments</p>
                          </div>
                        </b-col>
                        <b-col md="5" class="d-flex flex-row align-items-center justify-md-content-end pt-3 pt-md-0 pr-0">
                          <b-button variant="outline-secondary" :disabled="attachmentButtonClicked" @click="newFileUpload"><fa-icon icon="plus" /> Add Attachment</b-button>
                          <b-form-file v-show="false" @change="collapsed['email_attachments'] = false" autofocus v-for="(attachment, attachmentInd) in newEmailAttachments" v-model="newEmailAttachments[attachmentInd]" :key="attachmentInd" :id="`attachment-upload${attachmentInd}`"></b-form-file>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['email_attachments']">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-if="filteredNewEmailAttachments.length">
                      <b-col cols="12" class="">
                        <div class="d-flex flex-row flex-fill flex-wrap">
                          <span v-for="(attachment, attachmentInd) in newEmailAttachments" :key="`email-attachment${attachmentInd}`" v-show="attachment != null"><b-button :variant="hoveredAttachment == attachmentInd ? 'outline-danger' : 'outline-primary'" @mouseover="hoveredAttachment = attachmentInd" @mouseleave="hoveredAttachment = null" @click="rmAttachment(attachmentInd)" class="mr-2 mb-2" v-if="attachment != null"><fa-icon :icon="hoveredAttachment == attachmentInd ? 'times' : 'file-import'" class="mr-2"/>{{ attachment.name }}</b-button></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="!filteredNewEmailAttachments.length">
                      <b-col cols="12" class="d-flex justify-content-center">
                        <p class="mt-1 mb-0">There are no attachments currently selected.</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #modal-footer="{ ok }">
        <b-button @click="ok()"><fa-icon icon="paper-plane" /> Schedule</b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
import TemplateSearch from '../../components/TemplateSearch'
import CustomerSearch from '../../components/CustomerSearch'
import Quill from '../../components/Quill'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchMany(this.$route.query)
  },
  data: () => ({
    collapsed: {
      email_attachments: true
    },

    viewEmail: null,
    inboxType: 'All',

    templateContent: null,

    template: { id: null },

    replyingToEmail: null,
    newEmailSubject: null,
    newEmailContent: null,
    newEmailScheduledDateAt: null,
    newEmailScheduledTimeAt: null,
    newEmailAttachments: [],
    attachmentButtonClicked: false,
    hoveredAttachment: null,
    setEmailSchedule: false,
  }),
  mounted() {
    this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
    this.newEmailScheduledTimeAt = this.moment().format('HH:MM')
    this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
      if(modalId == 'email-modal') {
        this.replyingToEmail = null
        this.newEmailSubject = null
        this.newEmailContent = null
        this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
        this.newEmailScheduledTimeAt = this.moment().format('HH:MM')
        this.newEmailAttachments = []
        this.attachmentButtonClicked = false
        this.hoveredAttachment = null
        this.template = { id: null }
        this.templateContent = null
      }
    })
  },
  components: {
    FriendlyDate,
    ResourceList,
    TemplateSearch,
    CustomerSearch,
    Quill
  },
  computed: {
    ...mapGetters('emails', ['data', 'errors', 'isDeleting', 'isLoading', 'pagination']),
    ...mapGetters('customers', ['errors']),
    filteredNewEmailAttachments() {
      return this.newEmailAttachments.filter(attachment => {
        return (attachment != null)
      })
    },
    computedReplyingToIndex() {
      if(this.replyingToEmail) {
        return this.data.findIndex(email => {
          return email.id == this.replyingToEmail
        })
      }
    },
  },
  methods: {
    ...mapActions('emails', ['fetchMany']),
    ...mapActions('customers', ['doQueueEmail']),
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        this.viewEmail = null
        await this.fetchMany({ ...this.$route.query, page, inboxType: this.inboxType })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        this.viewEmail = null
        await this.fetchMany({ ...this.$route.query, query, inboxType: this.inboxType })
      }
    },
    async onFilterChange() {
      this.viewEmail = null
      await this.fetchMany({ ...this.$route.query, inboxType: this.inboxType })
    },

    toggleSelected(email) {
      this.viewEmail = (this.viewEmail != email.id) ? email.id : null
    },

    attachmentIsHovered(attachmentInd) {
      return (attachmentInd == this.hoveredAttachment)
    },

    toggleCollapse (name) {
      if(name == 'correspondence') this.resizeIFrames()
      this.collapsed[name] = !this.collapsed[name]
    },

    queueEmail() {
      let data = new FormData()

      data.append('subject', this.newEmailSubject)
      data.append('content', this.newEmailContent)
      data.append('scheduled_date', !this.setEmailSchedule ? this.moment().format('YYYY-MM-DD') : this.newEmailScheduledDateAt)
      data.append('scheduled_time', !this.setEmailSchedule ? this.moment().format('HH:MM') : this.newEmailScheduledTimeAt)
      data.append('replying_to', this.replyingToEmail)

      this.newEmailAttachments.forEach((attachment, attachmentInd) => {
        if(attachment != null) data.append(`attachments[${attachmentInd}]`, attachment)
      })
      
      this.doQueueEmail({ 'customer': this.data[this.computedReplyingToIndex].from.id, data}).then(response => {
        //
      })
    },

    newFileUpload() {
      this.attachmentButtonClicked = true
      this.newEmailAttachments.push(null)
      this.$nextTick().then(() => {
        document.getElementById(`attachment-upload${this.newEmailAttachments.length-1}`).click()
        setTimeout(() => {
          this.attachmentButtonClicked = false
        }, 500)
      })
    },

    templateUpdated() {
      this.templateContent = this.template.delta_content
    },
  },
  watch: {
    replyingToEmail() {
      if(this.replyingToEmail) {
        this.$root.$emit('bv::show::modal', 'email-modal')
      } 
    },
  }
}
</script>

<style>

</style>
