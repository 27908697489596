<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col cols="12">
          <GmapMap
            :center="{lat:54, lng:-2}"
            :zoom="7"
            :options="{
              streetViewControl: false,
              disableDefaultUi: true,
              fullscreenControl: false,
              mapTypeContro: false,
            }"
            map-type-id="terrain"
            style="width: 100%; height: calc(100vh - 11.5rem)"
          >
            <GmapInfoWindow
              key="van"
              v-if="showVan"
              :options="{
                content: '<span>BL70 RWY</em></span>',
                pixelOffset: {
                  width: 0,
                  height: -35,
                }
              }"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"/>
            <GmapMarker
              key="van-marker"
              v-if="showVan"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"
            />
            <GmapInfoWindow
              :key="`info${index}`"
              :options="getInfoWindowOptions(index)"
              :opened="true"
              :position="{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }"
              v-for="(repair, index) in computedRepairs"/>
            <GmapMarker
              :key="`marker${index}`"
              v-for="(repair, index) in computedRepairs"
              :position="{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }"
            />
          </GmapMap>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from '../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

window.repairRedirection = function(id) {
  window.dispatchEvent(new CustomEvent('view-repair', id))
}

export default {
  mixins: [currentUser],
  props: {
        data: {
            required: true,
            type: Array
        },
        showMap: Boolean,
  },
  data: () => ({
    van: {},
    vanInterval: null,
    showVan: false,
  }),
  created () {
    window.addEventListener('view-repair', payload => {
      this.$router.push({ name: 'dash.repairs.view', params: {id: payload } })
    })
    this.vanInterval = setInterval(() => {
      this.getVanMarkerCoords()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.vanInterval)
  },
  computed: {
    computedRepairs() {
        return this.data.filter(repair => {
            return ( repair.ticket.ticket_address && ( !isNaN(repair.ticket.ticket_address.latitude) && !isNaN(repair.ticket.ticket_address.longitude) ))
        })
    }
  },
  methods: {
    getVanMarkerCoords() {
      // Van 2 is BL70 RWY
      window.axios.get('/vans/2').then(response => {
        this.van = response.data
        this.showVan = true
      })
    },

    getInfoWindowOptions(ind) {
      return {
        content: '<span onclick="window.repairRedirection('+this.computedRepairs[ind].id+')">#' + this.computedRepairs[ind].id + ' ' + this.computedRepairs[ind].ticket.name + '<br><em>' + this.computedRepairs[ind].ticket.status + '</em></span>',
        pixelOffset: {
          width: 0,
          height: -35,
        }
      }
    }
  },
}
</script>

<style lang="scss">

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }
</style>
