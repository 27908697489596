<template>
  <b-container fluid>
    <TicketResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      :ticket-statuses="statuses"
      @search="onSearch">

      <template #filters>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ticket Status">
              <b-form-select v-model="ticketStatus" @change="onStatusChange">
                <b-form-select-option value="Default">All Except Completed</b-form-select-option>
                <b-form-select-option value="All">All</b-form-select-option>
                <b-form-select-option v-for="status in statuses" :key="status" :value="status">{{ status }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'dash.tickets.view', params: { id: item.id }}">#{{ item.id }} {{ item.name }} </router-link></p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.created_at" /></p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0 flex-fill" v-if="item.ticket_address">Postcode: {{ item.ticket_address.postcode }}</p>
            <p class="mb-0 text-muted" v-if="item.status">{{ item.status }}</p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1" v-if="item.latest_note">
            <p class="mb-0 text-muted font-italic">Latest Note: {{ item.latest_note.note }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this ticket.'" :to="{name: 'dash.tickets.view', params: { id: item.id }}"><b-icon icon="chat-left" /> View</b-button>
          </div>
        </div>
      </template>

    </TicketResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import TicketResourceList from '../../components/TicketResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchMany({
      ...this.$route.query,
      ticketStatus: this.ticketStatus
    })
    this.fetchStatuses().then(response => {
      this.statuses = response.data;
    })
  },
  components: {
    FriendlyDate,
    TicketResourceList
  },
  data: () => ({
    ticketStatus: 'Default',
    statuses: [],
  }),
  computed: {
    ...mapGetters('tickets', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('tickets', ['deleteMany', 'fetchMany', 'fetchStatuses']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      //if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({
          ...this.$route.query,
          page,
          ticketStatus: this.ticketStatus
        })
      //}
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query, ticketStatus: this.ticketStatus })
      }
    },
    async onStatusChange (status) {
      if (this.$route.query.status !== status) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, status } })
        await this.fetchMany({ ...this.$route.query, ticketStatus: this.ticketStatus })
      }
    }
  }
}
</script>

<style>

</style>
