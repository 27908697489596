<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Book Repair - Ticket #{{ ticket.id }}</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')">
                <b-form-input :disabled="true" :state="validationState(errors, 'name')" v-model="ticket.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Email" :invalid-feedback="validationInvalidFeedback(errors, 'email')">
                <b-form-input :disabled="true" :state="validationState(errors, 'email')" v-model="ticket.email" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Phone" :invalid-feedback="validationInvalidFeedback(errors, 'phone')">
                <b-form-input :disabled="true" :state="validationState(errors, 'phone')" v-model="ticket.phone" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="SPA Serial Number" :invalid-feedback="validationInvalidFeedback(errors, 'spa')">
                <b-form-input :disabled="true" :state="validationState(errors, 'spa')" v-model="ticket.spa" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Status" :invalid-feedback="validationInvalidFeedback(errors, 'status')">
                <b-form-input :disabled="true" :state="validationState(errors, 'status')" v-model="ticket.status" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Description of the Fault" :invalid-feedback="validationInvalidFeedback(errors, 'description')">
                <b-form-textarea :disabled="true" :state="validationState(errors, 'description')" v-model="ticket.description" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!isLoading">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('past_orders')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['past_orders'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Past Orders</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['past_orders']">
                  <b-col cols="12" v-if="ticket.customer.orders.length">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-for="(order, orderInd) in ticket.customer.orders" :key="`order${orderInd}`">
                      <b-col cols="12">
                        <b-card no-body class="mb-3 p-3">
                          <b-row>
                            <b-col cols="12">
                              <b-row>
                                <b-col class="d-flex flex-column align-items-start justify-content-center">
                                  <p class="h5 mb-0"><router-link v-b-popover.hover.left="'View Order.'" :to="{name: 'dash.orders.view', params: { id: order.id }}">Order #{{ order.id }}</router-link></p>
                                  <small class="mb-0 text-muted"><FriendlyDate :direction="'right'" :date="order.created_at"/></small>
                                </b-col>
                              </b-row>
                              <b-row v-if="order.finance_package_id" class="mt-2 mb-2">
                                <b-col cols="12">
                                  <div>
                                    <b-badge sm variant="primary">Finance</b-badge>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row v-if="order.additional_notes" class="mt-2 mb-2">
                                <b-col cols="12">
                                  <div class="d-flex flex-column">
                                    <small>Additional Notes:</small>
                                    <small class="text-muted white-space-pre">{{ order.additional_notes }}</small>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="mt-3">
                                <b-col cols="12">
                                  <h6 class="mb-2">Order Items:</h6>
                                  <b-card no-body class="p-3" v-for="item in order.items" :key="`orderItem${item.id}`">
                                    <b-row>
                                      <b-col cols="12">
                                        <div class="d-flex flex-column">
                                          <div class="d-flex flex-row flex-fill">
                                            <div class="flex-column flex-fill">
                                              <p class="mb-0 flex-fill">{{ item.product.name }}</p>
                                              <div class="flex-row">
                                                <b-badge variant="danger" class="mr-2" v-if="item.install_required" v-b-popover.hover.left="" pill>Installation Required</b-badge>
                                                <b-badge variant="danger" class="mr-2" v-if="item.crane_required" pill>Crane Required</b-badge>
                                                <b-badge variant="danger" class="mr-2" v-if="item.electrics_required" pill>Electrics Required</b-badge>
                                              </div>
                                              <p v-if="item.install_required" class="mb-0 mt-1 text-muted">Installation {{ item.install_at ? 'Scheduled: ' + moment(item.install_at).format('DD/MM/YYYY') : 'Not Scheduled.' }}</p>
                                            </div>
                                            <div class="flex-column text-right" v-if="item.supply_product">
                                              <p class="mb-0 text-muted">Expected {{ item.supply_product.expected_date ? moment(item.supply_product.expected_date).format('DD/MM/YYYY') : moment(item.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</p>
                                              <small class="mb-0 text-muted">{{ item.supply_product.supply.name }}</small>
                                            </div>
                                          </div>
                                          <div class="d-flex flex-column mt-3" v-if="item.extras.length">
                                            <b-card>
                                              <h6 class="mb-3">Extras:</h6>
                                              <b-table-simple small striped>
                                                <b-thead>
                                                  <b-tr>
                                                    <b-th>Name</b-th>
                                                    <b-th class="text-right"><span class="mr-2">Amount</span></b-th>
                                                  </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                  <b-tr v-for="(extra, extraInd) in item.extras" :key="`extra${item.id}-${extraInd}`">
                                                    <b-td>{{ extra.name }}</b-td>
                                                    <b-td class="text-right"><span class="mr-2">{{ extra.price | currencyFormat }}</span></b-td>
                                                  </b-tr>
                                                </b-tbody>
                                              </b-table-simple>
                                            </b-card>
                                          </div>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="!ticket.customer.orders.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no orders attached to this customer.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('address')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['address'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Address</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['address']">
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_1')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'address.address_line_1')" v-model="ticket.ticket_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'address.address_line_2')" v-model="ticket.ticket_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'address.city')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'address.city')" v-model="ticket.ticket_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'address.county')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'address.county')" v-model="ticket.ticket_address.county" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'address.postcode')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'address.postcode')" v-model="ticket.ticket_address.postcode" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('image_collapse')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['image_collapse'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Images</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['image_collapse']">
                  <b-col cols="12" md="12">
                    <h4>Serial Number Image</h4>
                    <b-img :src="returnFileUrl('serial_number_image')" fluid block></b-img>
                  </b-col>
                  <b-col cols="12" md="12">
                    <h4>Hot Tub Image</h4>
                    <b-img :src="returnFileUrl('hot_tub_image')" fluid block></b-img>
                  </b-col>
                  <b-col cols="12" md="12">
                    <h4>Problem File</h4>
                    <b-img v-if="returnFileType('problem_file') == 'image'" :src="returnFileUrl('problem_file')" fluid block></b-img>
                    <video class="w-100" v-if="returnFileType('problem_file') == 'video'" :src="returnFileUrl('problem_file')" controls></video>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('repair')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['repair'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Repair</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['repair']">
                  <b-col cols="12">
                    <b-form-group label="Team">
                      <team-search :error-feed="errors.errors" :error-key="'team_id'" :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated" v-model="repair.team" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'repair_date')">
                      <label @click="onCalendar">Repair Date</label>
                      <div @click="onCalendar" class="input form-control d-flex flex-row align-items-center" :class="{ 'is-invalid': (validationState(errors.errors, 'repair_date') === false) }" role="button" @mouseover="repairDatePickerHovered = true" @mouseleave="repairDatePickerHovered = false">
                        <b-icon class="input-icon" :icon="repairDatePickerHovered ? 'calendar-fill' : 'calendar'"></b-icon>
                        <p class="text-muted mb-0 ml-3" v-if="!repair.repair_date">No date selected</p>
                        <p class="mb-0 ml-3" v-else>{{ moment(repair.repair_date).format('dddd, D MMMM YYYY') }}</p>
                      </div>
                      <small class="text-danger" v-if="validationState(errors.errors, 'repair_date') === false" v-text="validationInvalidFeedback(errors.errors, 'repair_date')"></small>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Repair Time" :invalid-feedback="validationInvalidFeedback(errors.errors, 'repair_time')">
                      <b-form-timepicker :state="validationState(errors.errors, 'repair_time')"  v-model="repair.repair_time" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Duration (Minutes)" :invalid-feedback="validationInvalidFeedback(errors.errors, 'duration')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'duration')" v-model="repair.duration" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h4 font-weight-bold mb-0">Email Customer</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['repair']">
                  <b-col cols="12">
                    <b-form-group label="Email Content">
                      <b-form-textarea v-model="emailContent" rows="10"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <b-modal hide-footer id="calendar-modal" centered size="xl" title="Book Repair Date" v-model="calendarData.modal">
      <template #default>
        <b-row v-if="calendarData.modal">
          <b-col cols="12">
            <b-row>
              <b-col md="6" class="d-flex flex-column flex-fill">
                <RepairFullCalendar
                    selectable
                    @select="dateClick"
                    :data="data"
                    :repairEvents="repairEvents"
                    @getData="getCalendarData"
                ></RepairFullCalendar>
              </b-col>
              <b-col md="6" class="mt-3 mt-md-0">
                <BookRepairMap
                    :current-ticket="ticket"
                    :data="calendarData.nearbyRepairs"
                ></BookRepairMap>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-if="calendarData.modal && calendarLoading" class="d-flex justify-content-center">
            <b-spinner variant="secondary"></b-spinner>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
import TeamSearch from '../../components/TeamSearch'
import RepairFullCalendar from '../../components/RepairFullCalendar'
import BookRepairMap from '../../components/BookRepairMap'

export default {
  mixins: [validation, momentMixin],
  components: { FriendlyDate, TeamSearch, RepairFullCalendar, BookRepairMap },
  computed: {
    ...mapGetters('repairs', {
        'data': 'calendarData',
        'errors': 'errors',
        'isSaving': 'isSaving',
        'single': 'single',
        'single': 'single',
        'calendarLoading': 'isLoading'
      }),
    ...mapGetters('tickets', ['isLoading','single']),
    ...mapGetters('events', {
        'repairEvents': 'data'
    }),
  },
  data () {
    return {
      startDate: null,
      endDate: null,

      collapsed: {
        address: true,
        repair: false,
        image_collapse: true,
        notes: true,
        past_orders: true,
      },

      calendarData: {
        modal: false,
        nearbyRepairs: [],
      },
      repairDatePickerHovered: false,

      serial_number_image: null,
      hot_tub_image: null,
      problem_file: null,

      emailContent: null,

      ticket: {
        name: null,
        email: null,
        phone: null,
        spa: null,
        status: null,
        description: null,
        serial_number_image: null,
        hot_tub_image: null,
        problem_file: null,
        accept_disclaimer: false,
        ticket_address: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          county: null,
          postcode: null,
        },
      },
      
      repair: {
        id: null,
        ticket_id: null,
        team_id: null,
        team: { id: null },
        repair_date: null,
        repair_time: null,
        duration: 30,
      }
    }
  },
  created() {
    var self = this;
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      switch(modalId) {
        case 'calendar-modal': this.calendarData.modal = false; break;
      }
    })
  },
  methods: {

    ...mapActions('tickets', ['fetch']),
    ...mapActions('repairs', ['save', 'fetchManyCalendar', 'fetchNearbyRepairs']),
    ...mapActions('events', ['fetchManyRepairEvents']),

    dateClick(info) {
      this.repair.repair_date = this.moment(info.start).format('YYYY-MM-DD')
      this.repair.repair_time = this.moment(info.start).format('HH:mm:ss')
      this.repair.duration = this.moment(info.end).diff(this.moment(info.start), 'minutes')
      this.calendarData.modal = false
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    teamUpdated() {
      this.repair.team_id = this.repair.team.id;
    },

    submit () {
      let data = {
        id: null,
        ticket_id: this.$route.params.id,
        team_id: this.repair.team_id,
        repair_date: this.repair.repair_date,
        repair_time: this.repair.repair_time,
        duration: this.repair.duration,
        email_content: this.emailContent
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.tickets.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Repair #${response.data.id}`,
              text: `The repair has been booked successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },

    async onCalendar () {
        this.calendarData.modal = true
        window.dispatchEvent(new Event('resize'))
    },

    getCalendarData(payload) {
        if(payload) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': this.selectedTeams
        })
        this.fetchManyRepairEvents({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': this.selectedTeams
        })
        // Get the nearby repairs
        this.fetchNearbyRepairs({ ticket: this.ticket.id })
          .then(response => {
            this.calendarData.nearbyRepairs = response.data
          })
        window.dispatchEvent(new Event('resize'))
    },

    fileChanged(fileKey) {
      this.ticket[fileKey] = this[fileKey];
    },

    returnFileUrl(fileKey) {
      if(this.ticket[fileKey]) {
        return this.ticket[fileKey].url ?? URL.createObjectURL(this.ticket[fileKey]);
      }
    },

    returnFileType(fileKey) {
      if(this.ticket[fileKey]) {
        return this.ticket[fileKey].mimetype.split('/')[0];
      }
    }
  },
  watch: {
    single () {
      this.ticket = this.single
    },
  }
}
</script>

<style lang="scss">
  div.input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .input-icon {
        font-size: 125%!important;
        color: #000;
      }

      &.is-invalid {
        .input-icon {
          color: #dc3545;
        }
      }
  }

</style>
