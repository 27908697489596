import {
  TEAMS_BATCH_DELETE_REQUEST,
  TEAMS_BATCH_DELETE_SUCCESS,
  TEAMS_BATCH_DELETE_FAILURE,
  TEAMS_FETCH_MANY_REQUEST,
  TEAMS_FETCH_MANY_SUCCESS,
  TEAMS_FETCH_MANY_FAILURE,
  TEAMS_FETCH_MANY_NO_PAGINATE_REQUEST,
  TEAMS_FETCH_MANY_NO_PAGINATE_SUCCESS,
  TEAMS_FETCH_MANY_NO_PAGINATE_FAILURE,
  TEAMS_FETCH_REQUEST,
  TEAMS_FETCH_SUCCESS,
  TEAMS_FETCH_FAILURE,
  TEAMS_SAVE_REQUEST,
  TEAMS_SAVE_SUCCESS,
  TEAMS_SAVE_FAILURE,

  EMPTY_TEAMS,

  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(TEAMS_BATCH_DELETE_REQUEST)
    return window.axios.post('/teams', { _method: 'DELETE', ids }).then(response => {
      commit(TEAMS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TEAMS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(TEAMS_FETCH_MANY_REQUEST)
    return window.axios.get('/teams', { params: { query, page } }).then(response => {
      commit(TEAMS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TEAMS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchAll ({ commit }) {
    commit(TEAMS_FETCH_MANY_NO_PAGINATE_REQUEST)
    return window.axios.get('/teams/all').then(response => {
      commit(TEAMS_FETCH_MANY_NO_PAGINATE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TEAMS_FETCH_MANY_NO_PAGINATE_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, { id, extraParams }) {
    commit(TEAMS_FETCH_REQUEST)
    return window.axios.get(`/teams/${id}`, { params: { ...extraParams } }).then(response => {
      commit(TEAMS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TEAMS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, team) {
    const path = team.id ? `/teams/${team.id}` : '/teams'
    const method = team.id ? 'put' : 'post'

    commit(TEAMS_SAVE_REQUEST)
    return window.axios[method](path, team).then(response => {
      commit(TEAMS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(TEAMS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(TEAMS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_TEAMS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
