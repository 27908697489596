<template>
  <router-view />
</template>

<script>
import ticketModule from '../../store/tickets'
import customerModule from '../../store/customers'
import teamModule from '../../store/teams'
import repairModule from '../../store/repairs'
import eventModule from '../../store/events'

export default {
  beforeCreate () {
    this.$store.registerModule('tickets', ticketModule)
    this.$store.registerModule('customers', customerModule)
    this.$store.registerModule('teams', teamModule)
    this.$store.registerModule('repairs', repairModule)
    this.$store.registerModule('events', eventModule)
  },
  destroyed () {
    this.$store.unregisterModule('tickets')
    this.$store.unregisterModule('customers')
    this.$store.unregisterModule('teams')
    this.$store.unregisterModule('repairs')
    this.$store.unregisterModule('events')
  }
}
</script>
