export const REPAIRS_BATCH_DELETE_REQUEST = 'REPAIRS_BATCH_DELETE_REQUEST'
export const REPAIRS_BATCH_DELETE_SUCCESS = 'REPAIRS_BATCH_DELETE_SUCCESS'
export const REPAIRS_BATCH_DELETE_FAILURE = 'REPAIRS_BATCH_DELETE_FAILURE'

export const REPAIRS_FETCH_MANY_REQUEST = 'REPAIRS_FETCH_MANY_REQUEST'
export const REPAIRS_FETCH_MANY_SUCCESS = 'REPAIRS_FETCH_MANY_SUCCESS'
export const REPAIRS_FETCH_MANY_FAILURE = 'REPAIRS_FETCH_MANY_FAILURE'

export const REPAIRS_FETCH_CALENDAR_REQUEST = 'REPAIRS_FETCH_CALENDAR_REQUEST'
export const REPAIRS_FETCH_CALENDAR_SUCCESS = 'REPAIRS_FETCH_CALENDAR_SUCCESS'
export const REPAIRS_FETCH_CALENDAR_FAILURE = 'REPAIRS_FETCH_CALENDAR_FAILURE'

export const REPAIRS_FETCH_MAP_REQUEST = 'REPAIRS_FETCH_MAP_REQUEST'
export const REPAIRS_FETCH_MAP_SUCCESS = 'REPAIRS_FETCH_MAP_SUCCESS'
export const REPAIRS_FETCH_MAP_FAILURE = 'REPAIRS_FETCH_MAP_FAILURE'

export const REPAIRS_FETCH_REQUEST = 'REPAIRS_FETCH_REQUEST'
export const REPAIRS_FETCH_SUCCESS = 'REPAIRS_FETCH_SUCCESS'
export const REPAIRS_FETCH_FAILURE = 'REPAIRS_FETCH_FAILURE'

export const REPAIRS_SAVE_REQUEST = 'REPAIRS_SAVE_REQUEST'
export const REPAIRS_SAVE_SUCCESS = 'REPAIRS_SAVE_SUCCESS'
export const REPAIRS_SAVE_FAILURE = 'REPAIRS_SAVE_FAILURE'

export const REPAIRS_PROCESS_REQUEST = 'REPAIRS_PROCESS_REQUEST'
export const REPAIRS_PROCESS_SUCCESS = 'REPAIRS_PROCESS_SUCCESS'
export const REPAIRS_PROCESS_FAILURE = 'REPAIRS_PROCESS_FAILURE'

export const REPAIRS_CALENDAR_REQUEST = 'REPAIRS_CALENDAR_REQUEST'
export const REPAIRS_CALENDAR_SUCCESS = 'REPAIRS_CALENDAR_SUCCESS'
export const REPAIRS_CALENDAR_FAILURE = 'REPAIRS_CALENDAR_FAILURE'

export const EMPTY_REPAIRS = 'EMPTY_REPAIRS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'