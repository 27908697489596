<template>
  <b-container fluid>
    <ResourceList
      create-to="dash.templates.create" resource="template" can-delete title="Templates" :has-buttons="false"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @paginate="onPaginate" @delete-many="onDeleteMany"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-row flex-fill p-2">
          <router-link class="h5 flex-fill mb-0 text-dark" :to="{name: 'dash.templates.update', params: { id: item.id }}">{{ item.name }}</router-link>
          <p class="align-self-end mb-0 text-muted"><FriendlyDate :date="item.created_at" /></p>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchMany(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('templates', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('templates', ['deleteMany', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    },
  }
}
</script>

<style>

</style>
