<template>
  <resource-search @delete="() => this.$emit('delete')"  @input="id => this.$emit('input', id)" :error-feed="errorFeed" :error-key="errorKey" module="teams" :show-delete="showDelete" :wait-for-input="waitForInput" :key="value.id" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
      <div class="d-flex flex-fill align-items-center">
        <p class="font-weight-bold mb-0  w-100-m-1">{{ result.name }}</p>
      </div>
    </template>

    <template #selected="{ selected }">
      <div class="d-flex flex-column justify-content-center align-self-stretch flex-fill">
        <p class="font-weight-bold mb-0  w-100-m-1">{{ selected.name }}</p>
        <small class="text-danger" v-if="selected.deleted_at">This team has been deleted.</small>
      </div>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
    orderItem: {
      required: false,
      default: null
    }
  },
  computed: {
    extraFetchParams() {
      var returnParams = {};

      if(this.type) {
        returnParams = { ...returnParams, type: this.type, order_item_id: this.orderItem };
      }

      return returnParams;
    }
  },
}
</script>

<style>

</style>