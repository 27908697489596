import {
  REPAIRS_BATCH_DELETE_REQUEST,
  REPAIRS_BATCH_DELETE_SUCCESS,
  REPAIRS_BATCH_DELETE_FAILURE,
  REPAIRS_FETCH_MANY_REQUEST,
  REPAIRS_FETCH_MANY_SUCCESS,
  REPAIRS_FETCH_MANY_FAILURE,
  REPAIRS_FETCH_CALENDAR_REQUEST,
  REPAIRS_FETCH_CALENDAR_SUCCESS,
  REPAIRS_FETCH_CALENDAR_FAILURE,
  REPAIRS_FETCH_MAP_REQUEST,
  REPAIRS_FETCH_MAP_SUCCESS,
  REPAIRS_FETCH_MAP_FAILURE,
  REPAIRS_FETCH_REQUEST,
  REPAIRS_FETCH_SUCCESS,
  REPAIRS_FETCH_FAILURE,
  REPAIRS_SAVE_REQUEST,
  REPAIRS_SAVE_SUCCESS,
  REPAIRS_SAVE_FAILURE,
  REPAIRS_PROCESS_REQUEST,
  REPAIRS_PROCESS_SUCCESS,
  REPAIRS_PROCESS_FAILURE,
  REPAIRS_CALENDAR_REQUEST,
  REPAIRS_CALENDAR_SUCCESS,
  REPAIRS_CALENDAR_FAILURE,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(REPAIRS_BATCH_DELETE_REQUEST)
    return window.axios.post('/repairs', { _method: 'DELETE', ids }).then(response => {
      commit(REPAIRS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, type = '' }) {
    commit(REPAIRS_FETCH_MANY_REQUEST)
    return window.axios.get('/repairs', { params: { query, page, type } }).then(response => {
      commit(REPAIRS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchNearbyRepairs ({ commit }, { ticket }) {
    return window.axios.get(`/repairs/nearby/${ticket}`)
      .then(response => {
        return response
      }).catch(error => {
        throw error
      })
  },
  fetchManyCalendar ({ commit }, { start_date = null, end_date = null, teams = [], query }) {
    commit(REPAIRS_CALENDAR_REQUEST)
    return window.axios.get('/repairs/calendar', { params: { start_date, end_date, teams, query } }).then(response => {
      commit(REPAIRS_CALENDAR_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_CALENDAR_FAILURE, error)
      throw error
    })
  },
  fetchCalendar ({ commit }) {
    commit(REPAIRS_FETCH_CALENDAR_REQUEST)
    return window.axios.get('/repairs/calendar').then(response => {
      commit(REPAIRS_FETCH_CALENDAR_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_FETCH_CALENDAR_FAILURE, error)
      throw error
    })
  },
  fetchMap ({ commit }) {
    commit(REPAIRS_FETCH_MAP_REQUEST)
    return window.axios.get('/repairs/map').then(response => {
      commit(REPAIRS_FETCH_MAP_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_FETCH_MAP_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(REPAIRS_FETCH_REQUEST)
    return window.axios.get(`/repairs/${id}`).then(response => {
      commit(REPAIRS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPAIRS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, repair) {
    const method = `post`
    const path = `/repairs`

    commit(REPAIRS_SAVE_REQUEST)
    return window.axios[method](path, repair).then(response => {
      commit(REPAIRS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(REPAIRS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(REPAIRS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  complete ({ commit }, { repairId, repair }) {
    const method = `post` // repairId ? 'put' : 'post'
    const path = `/repairs/${repairId}/complete`

    commit(REPAIRS_SAVE_REQUEST)
    return window.axios[method](path, repair, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      commit(REPAIRS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(REPAIRS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(REPAIRS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  repairDropped ({ commit }, { repairId, start, end }) {
    commit(REPAIRS_SAVE_REQUEST)
    return window.axios.post(`/repairs/${repairId}/dropped`, { start, end }).then(response => {
      commit(REPAIRS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(REPAIRS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(REPAIRS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_REPAIRS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  },

  // Non Crud Functions
  fetchStatuses() {
    return window.axios.get('/ticket-statuses');
  }
}
