<template>
    <div class="overlay" :class="{ 'show': value }" @click="e => ( $emit('input', false) )">
        <b-row class="h-100">
            <b-col cols="10" offset="2" md="4" offset-md="8" @click="e => ( e.stopPropagation() )">
                <div class="bg-light panel" :class="{ 'show': value }">
                    <slot/>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        value: { required: true, type: Boolean }
    },
}
</script>

<style lang="scss" scoped>
    div.overlay {
        position: fixed;
        width: 100%;
        height: calc(100vh - 68px);
        top: 68px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0, 0.5);
        z-index: 1000;
        opacity: 0;
        transition: visibility 0.2s 0s, opacity 0.2s linear;
        visibility: hidden;

        &.show {
            cursor: pointer;
            visibility: visible;
            opacity: unset;
            background-color:  rgba(0,0,0,0.5);
        }

        .panel {
            position: relative;
            height: calc(100vh - 68px);
            overflow-y: auto;
            width: 100%;
            right: -100%;
            border-left: 1px solid darken($light, 10%);
            transition: right 0.2s;
            cursor: default;

            &.show {
                right: 0;
            }
        }
    }
</style>