import {
  EVENTS_BATCH_DELETE_REQUEST,
  EVENTS_BATCH_DELETE_SUCCESS,
  EVENTS_BATCH_DELETE_FAILURE,
  EVENTS_FETCH_MANY_REQUEST,
  EVENTS_FETCH_MANY_SUCCESS,
  EVENTS_FETCH_MANY_FAILURE,
  EVENTS_SAVE_REQUEST,
  EVENTS_SAVE_SUCCESS,
  EVENTS_SAVE_FAILURE,

  EMPTY_EVENTS,
  CLEAR_ERRORS
} from './types'

export default {
  fetchManyInstallationEvents ({ commit }, { start_date, end_date, teams = [], query }) {
    commit(EVENTS_FETCH_MANY_REQUEST)
    return window.axios.get('/events/installations', { params: { start_date, end_date, teams, query } }).then(response => {
      commit(EVENTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EVENTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  createInstallEvent({ commit }, event) {
    commit(EVENTS_SAVE_REQUEST)
    const path = event.id ? `/events/installations/${event.id}` : '/events/installations'
    return window.axios.post(path, event).then(response => {
      commit(EVENTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(EVENTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(EVENTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  deleteInstallEvent ({ commit }, event) {
    commit(EVENTS_BATCH_DELETE_REQUEST)
    return window.axios.post(`/events/installations/${event.id}`, { _method: 'DELETE' }).then(response => {
      commit(EVENTS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EVENTS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyRepairEvents ({ commit }, { start_date, end_date, teams = [], query }) {
    commit(EVENTS_FETCH_MANY_REQUEST)
    return window.axios.get('/events/repairs', { params: { start_date, end_date, teams, query } }).then(response => {
      commit(EVENTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EVENTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  createRepairEvent({ commit }, event) {
    commit(EVENTS_SAVE_REQUEST)
    const path = event.id ? `/events/repairs/${event.id}` : '/events/repairs'
    return window.axios.post(path, event).then(response => {
      commit(EVENTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(EVENTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(EVENTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  repairEventDropped({ commit }, { eventId, start = null, end = null }) {
    commit(EVENTS_SAVE_REQUEST)
    return window.axios.post(`/events/repairs/dropped/${eventId}`, { start, end }).then(response => {
      commit(EVENTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(EVENTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(EVENTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  deleteRepairEvent ({ commit }, event) {
    commit(EVENTS_BATCH_DELETE_REQUEST)
    return window.axios.post(`/events/repairs/${event.id}`, { _method: 'DELETE' }).then(response => {
      commit(EVENTS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EVENTS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_EVENTS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
