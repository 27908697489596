var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"calc(100vh - 11.5rem)"},attrs:{"center":{lat:54, lng:-2},"zoom":7,"options":{
            streetViewControl: false,
            disableDefaultUi: true,
            fullscreenControl: false,
            mapTypeContro: false,
          },"map-type-id":"terrain"}},[(_vm.showVan)?_c('GmapInfoWindow',{key:"van",attrs:{"options":{
              content: '<span>BL70 RWY</em></span>',
              pixelOffset: {
                width: 0,
                height: -35,
              }
            },"position":{ lat:Number(_vm.van.lat), lng: Number(_vm.van.lon) }}}):_vm._e(),(_vm.showVan)?_c('GmapMarker',{key:"van-marker",attrs:{"position":{ lat:Number(_vm.van.lat), lng: Number(_vm.van.lon) }}}):_vm._e(),_vm._l((_vm.computedRepairs),function(repair,index){return _c('GmapInfoWindow',{key:("info" + index),attrs:{"options":_vm.getInfoWindowOptions(index),"opened":true,"position":{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }}})}),_vm._l((_vm.computedRepairs),function(repair,index){return _c('GmapMarker',{key:("marker" + index),attrs:{"position":{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }}})})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }