<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Ticket</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">Customer</p>
                    <p class="mb-0 text-muted">Who is having the issue?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <customer-search class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="customer" @input="customerUpdated" v-model="ticket.customer" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="ticket.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Email" :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'email')" v-model="ticket.email" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Phone" :invalid-feedback="validationInvalidFeedback(errors.errors, 'phone')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'phone')" v-model="ticket.phone" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="SPA Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'spa')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'spa')" v-model="ticket.spa" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Status" :invalid-feedback="validationInvalidFeedback(errors.errors, 'newStatus')">
                <b-form-select :disabled="isSaving" :options="statuses" :state="validationState(errors.errors, 'newStatus')" v-model="ticket.status" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Description of the Fault" :invalid-feedback="validationInvalidFeedback(errors.errors, 'description')">
                <b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'description')" v-model="ticket.description" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('address')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['address'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Address</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['address']">
                  <b-col cols="12">
                    <b-form-group label="Postcode Search" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.postcode')">
                      <postcode-search :disabled="isSaving" @address="address => { this.ticket.ticket_address = address }" v-model="ticket.ticket_address.postcode"></postcode-search>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.address_line_1')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'address.address_line_1')" v-model="ticket.ticket_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.address_line_2')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'address.address_line_2')" v-model="ticket.ticket_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.city')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'address.city')" v-model="ticket.ticket_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.county')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'address.county')" v-model="ticket.ticket_address.county" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('image_collapse')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['image_collapse'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Images</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['image_collapse']">
                  <b-col cols="12" md="12">
                    <b-img :src="returnFileUrl('serial_number_image')" fluid block></b-img>
                    <b-form-group label="Hot Tub Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'serial_number_image')">
                        <b-form-file
                          v-model="serial_number_image"
                          :state="validationState(errors.errors, 'serial_number_image')"
                          @input="fileChanged('serial_number_image')"
                          :placeholder="serial_number_image ? serial_number_image.name : 'Choose a file or drop it here...'"
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="12">
                    <b-img :src="returnFileUrl('hot_tub_image')" fluid block></b-img>
                    <b-form-group label="Hot Tub Photo" :invalid-feedback="validationInvalidFeedback(errors.errors, 'hot_tub_image')">
                        <b-form-file
                          v-model="hot_tub_image"
                          :state="validationState(errors.errors, 'hot_tub_image')"
                          @input="fileChanged('hot_tub_image')"
                          :placeholder="hot_tub_image ? hot_tub_image.name : 'Choose a file or drop it here...'"
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="12">
                    <b-img :src="returnFileUrl('problem_file')" fluid block></b-img>
                    <b-form-group label="Problem Photo/Video" :invalid-feedback="validationInvalidFeedback(errors.errors, 'problem_file')">
                        <b-form-file
                          v-model="problem_file"
                          :state="validationState(errors.errors, 'problem_file')"
                          @input="fileChanged('problem_file')"
                          :placeholder="problem_file ? problem_file.name : 'Choose a file or drop it here...'"
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <!-- Ticket Notes -->
          <b-row class="mb-4">
              <b-col cols="12">
                  <b-card>
                      <b-row>
                          <b-col class="d-flex flex-row" cols="12" md="6" @click.prevent="toggleCollapse('ticket_notes')" role="button">
                              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['ticket_notes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                              <div class="d-flex flex-column flex-fill ml-4">
                                  <p class="h4 font-weight-bold mb-0">Ticket Notes</p>
                              </div>
                          </b-col>
                      </b-row>
                      <b-row class="mt-4" v-if="!collapsed['ticket_notes']">
                          <!-- Add New Note Field -->
                          <b-col cols="12">
                              <hr class="mt-0" />
                              <b-form-group label="Add a Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')">
                                  <b-form-textarea :state="validationState(errors.errors, 'newNote')" v-model="newNote" placeholder="Insert note here..." size="sm"></b-form-textarea>
                              </b-form-group>
                              <div class="d-flex flex-row flex-fill">
                                <b-button variant="primary" block @click="addNote">Add Note</b-button>
                              </div>
                          </b-col>
                          <b-col cols="12" v-if="ticket.notes.length">
                              <hr />
                              <b-row v-for="(note, noteInd) in ticket.notes" :key="noteInd">
                                  <b-col cols="12" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note.' + noteInd + '.note')">
                                      <b-card no-body class="p-4 mt-3">
                                        <b-row>
                                          <b-col cols="11">
                                            <p class="note mb-0">{{ note.note }}</p>
                                          </b-col>
                                          <b-col cols="1" class="d-flex flex-row align-items-start justify-content-end">
                                            <b-button variant="danger" @click="rmNote(noteInd)" size="sm"><fa-icon :icon="'trash'"/></b-button>
                                          </b-col>
                                        </b-row>
                                        <hr />
                                        <b-row>
                                            <b-col cols="9" class="d-flex flex-row justify-content-start align-items-center">
                                                <p class="note-created mb-0">Left by: {{ note.user.name }}</p>
                                            </b-col>
                                            <b-col cols="3" class="d-flex flex-row justify-content-end align-items-center">
                                                <p class="note-created text-muted mb-0"><FriendlyDate :date="note.created_at"></FriendlyDate></p>
                                            </b-col>
                                        </b-row>
                                      </b-card>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-card>
              </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="8">
              <b-row>
                <b-col cols="12" class="d-flex flex-column">
                  <b-form-group  :invalid-feedback="validationInvalidFeedback(errors.errors, 'accept_disclaimer')">
                    <b-form-checkbox
                      id="accept-disclaimer"
                      name="accept-disclaimer"
                      v-model="ticket.accept_disclaimer"
                      :value="1"
                      :unchecked-value="0">
                      Accept Disclaimer
                    </b-form-checkbox>
                  </b-form-group>
                  <small class="text-muted">If we are called out on to site and no fault or issue is discovered we have a £110 minimum call out charge and £96 per hour for any further time required, any faults relating to electrical tripping of mcb and rcd a valid electrical test certificate must be produced prior to an on site visit.</small>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="d-flex flex-row" cols="12" md="4">
              <b-button block class="align-self-start mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import CustomerSearch from '../../components/CustomerSearch';
import PostcodeSearch from '../../components/PostcodeSearch';

export default {
  mixins: [validation, currentUser, momentMixin],
  components: {CustomerSearch, PostcodeSearch},
  computed: {
    ...mapGetters('tickets', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      collapsed: {
        address: false,
        image_collapse: false,
      },

      statuses: [],

      newNote: null,

      serial_number_image: null,
      hot_tub_image: null,
      problem_file: null,

      ticket: {
        customer_id: null,
        customer: { id: null },
        name: null,
        email: null,
        phone: null,
        spa: null,
        status: 'New Issue',
        description: null,
        serial_number_image: null,
        hot_tub_image: null,
        problem_file: null,
        accept_disclaimer: false,
        ticket_address: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          county: null,
          postcode: null,
        },
        notes: [],
      }
    }
  },
  created () {
    if (this.$route.params.customer_id) {
      this.ticket.customer_id = this.$route.params.customer_id;
      this.ticket.customer.id = this.$route.params.customer_id;
      // setTimeout(() => { this.customerUpdated() }, 400)
    }else if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
    this.fetchStatuses().then(response => {
      this.statuses = response.data;
    });
  },
  methods: {
    ...mapActions('tickets', ['fetch', 'save', 'fetchStatuses']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit () {
      let formData = new FormData();
      if(this.ticket.name) formData.append('name', this.ticket.name);
      if(this.ticket.email) formData.append('email', this.ticket.email);
      if(this.ticket.phone) formData.append('phone', this.ticket.phone);
      if(this.ticket.spa) formData.append('spa', this.ticket.spa);
      if(this.ticket.status) formData.append('status', this.ticket.status);
      if(this.ticket.description) formData.append('description', this.ticket.description);
      if(this.ticket.customer_id) formData.append('customer_id', this.ticket.customer_id);

      this.ticket.notes.forEach((note, noteIndex) => {
        formData.append('notes['+noteIndex+'][id]', note.id ?? '');
        formData.append('notes['+noteIndex+'][note]', note.note);
      })
      
      if(Object(this.ticket.serial_number_image).hasOwnProperty('id')) {
        formData.append('serial_number_image', this.ticket.serial_number_image.id);
      }else{
        formData.append('serial_number_image', this.ticket.serial_number_image);
      }

      if(Object(this.ticket.hot_tub_image).hasOwnProperty('id')) {
        formData.append('hot_tub_image', this.ticket.hot_tub_image.id);
      }else{
        formData.append('hot_tub_image', this.ticket.hot_tub_image);
      }

      if(Object(this.ticket.problem_file).hasOwnProperty('id')) {
        formData.append('problem_file', this.ticket.problem_file.id);
      }else{
        formData.append('problem_file', this.ticket.problem_file);
      }
      
      formData.append('accept_disclaimer', this.ticket.accept_disclaimer);
      formData.append('address[address_line_1]', this.ticket.ticket_address.address_line_1);
      formData.append('address[address_line_2]', this.ticket.ticket_address.address_line_2);
      formData.append('address[city]', this.ticket.ticket_address.city);
      formData.append('address[county]', this.ticket.ticket_address.county);
      formData.append('address[postcode]', this.ticket.ticket_address.postcode);

      this.save({ 'ticketId': this.$route.params.id, 'ticket': formData }).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.tickets.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Ticket #${response.data.id}`,
              subtitle: response.data.status,
              text: `The ticket has been ${this.$route.params.id ? 'updated' : 'raised'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },

    addNote() {
      if(this.newNote) {
        this.ticket.notes = [{
            id: null,
            note: this.newNote,
            user: { name: this.currentUser.name },
            created_at: this.moment()
          }, ...this.ticket.notes]
        this.newNote = null
      }
    },

    rmNote(ind) {
      this.ticket.notes.splice(ind, 1)
    },

    customerUpdated() {
      if(!this.$route.params.id || this.ticket.customer_id != this.ticket.customer.id) {
        if(this.ticket.customer.id != null) {
          this.ticket.customer_id = this.ticket.customer.id
          this.ticket.name = `${this.ticket.customer.forename} ${this.ticket.customer.surname}`
          this.ticket.email = this.ticket.customer.email
          this.ticket.phone = this.ticket.customer.mobile
          if(!this.ticket.phone) this.ticket.phone = this.ticket.customer.landline
          if(!this.ticket.phone) this.ticket.phone = this.ticket.customer.second_mobile
          if(this.ticket.customer.shipping_address) this.ticket.ticket_address = this.ticket.customer.shipping_address
        }else{
          this.ticket.customer_id = null
          this.ticket.name = null
          this.ticket.email = null
          this.ticket.phone = null
          this.ticket.ticket_address = {
            address_line_1: null,
            address_line_2: null,
            city: null,
            county: null,
            postcode: null,
          }
        }
      }
    },

    fileChanged(fileKey) {
      this.ticket[fileKey] = this[fileKey];
    },

    returnFileUrl(fileKey) {
      if(this.ticket[fileKey]) {
        return this.ticket[fileKey].url ?? URL.createObjectURL(this.ticket[fileKey]);
      }
    }
  },
  watch: {
    single () {
      this.ticket = this.single
    }
  }
}
</script>

<style>

</style>
