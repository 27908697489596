export const EVENTS_BATCH_DELETE_REQUEST = 'EVENTS_BATCH_DELETE_REQUEST'
export const EVENTS_BATCH_DELETE_SUCCESS = 'EVENTS_BATCH_DELETE_SUCCESS'
export const EVENTS_BATCH_DELETE_FAILURE = 'EVENTS_BATCH_DELETE_FAILURE'

export const EVENTS_FETCH_MANY_REQUEST = 'EVENTS_FETCH_MANY_REQUEST'
export const EVENTS_FETCH_MANY_SUCCESS = 'EVENTS_FETCH_MANY_SUCCESS'
export const EVENTS_FETCH_MANY_FAILURE = 'EVENTS_FETCH_MANY_FAILURE'

export const EVENTS_SAVE_REQUEST = 'EVENTS_SAVE_REQUEST'
export const EVENTS_SAVE_SUCCESS = 'EVENTS_SAVE_SUCCESS'
export const EVENTS_SAVE_FAILURE = 'EVENTS_SAVE_FAILURE'

export const EMPTY_EVENTS = 'EMPTY_EVENTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
