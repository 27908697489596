<template>
  <router-view />
</template>

<script>
import emailModule from '../../store/emails'
import templateModule from '../../store/templates'
import customerModule from '../../store/customers'

export default {
  beforeCreate () {
    this.$store.registerModule('emails', emailModule)
    this.$store.registerModule('templates', templateModule)
    this.$store.registerModule('customers', customerModule)
  },
  destroyed () {
    this.$store.unregisterModule('emails')
    this.$store.unregisterModule('templates')
    this.$store.unregisterModule('customers')
  }
}
</script>
