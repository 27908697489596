var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"calc(100vh - 11.5rem)"},attrs:{"center":{ lat:Number(_vm.currentTicket.ticket_address.latitude), lng: Number(_vm.currentTicket.ticket_address.longitude) },"zoom":10,"options":{
          streetViewControl: false,
          disableDefaultUi: true,
          fullscreenControl: false,
          mapTypeContro: false,
        },"map-type-id":"terrain"},on:{"click":_vm.mapClicked}},[(_vm.youAreHere)?_c('GmapInfoWindow',{attrs:{"options":{
                content: '<span>You Are Here</em></span>',
                pixelOffset: {
                    width: 0,
                    height: -35,
                }
            },"opened":true,"position":{ lat:Number(_vm.currentTicket.ticket_address.latitude), lng: Number(_vm.currentTicket.ticket_address.longitude) }}}):_vm._e(),_c('GmapMarker',{key:"current-repair-ticket",attrs:{"icon":"https://maps.google.com/mapfiles/ms/icons/blue-dot.png","position":{ lat:Number(_vm.currentTicket.ticket_address.latitude), lng: Number(_vm.currentTicket.ticket_address.longitude) }},on:{"click":_vm.selectYouAreHere}}),(_vm.selectedRepair)?_c('GmapInfoWindow',{attrs:{"options":{
                content: '<span>'+ _vm.moment(_vm.selectedRepair.repair_date).format('DD/MM/YYYY') +'</em></span>',
                pixelOffset: {
                    width: 0,
                    height: -35,
                }
            },"opened":true,"position":{ lat:Number(_vm.selectedRepair.ticket.ticket_address.latitude), lng: Number(_vm.selectedRepair.ticket.ticket_address.longitude) }}}):_vm._e(),_vm._l((_vm.computedRepairs),function(repair,index){return _c('GmapMarker',{key:("marker" + index),attrs:{"icon":"https://maps.google.com/mapfiles/ms/icons/red-dot.png","position":{ lat:Number(repair.ticket.ticket_address.latitude), lng: Number(repair.ticket.ticket_address.longitude) }},on:{"click":function($event){return _vm.selectRepair(repair)}}})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }