<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Template</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="template.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <Quill min-height="600px" @delta="delta => template.delta_content = delta" :delta-content="template.delta_content" v-model="template.content"/>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" md="4">
              <b-button block class="align-self-start mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import Quill from '../../components/Quill';

export default {
  mixins: [ validation ],
  components: { Quill },
  computed: {
    ...mapGetters('templates', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      template: {
        id: null,
        name: null,
        content: null,
        delta_content: null,
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('templates', ['fetch', 'save']),

    submit () {
      this.save(this.template).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.templates.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Template #${response.data.id}`,
              text: `The template has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },
  },
  watch: {
    single () {
      this.template = this.single
    }
  }
}
</script>

<style>

</style>
