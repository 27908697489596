export const TEAMS_BATCH_DELETE_REQUEST = 'TEAMS_BATCH_DELETE_REQUEST'
export const TEAMS_BATCH_DELETE_SUCCESS = 'TEAMS_BATCH_DELETE_SUCCESS'
export const TEAMS_BATCH_DELETE_FAILURE = 'TEAMS_BATCH_DELETE_FAILURE'

export const TEAMS_FETCH_MANY_REQUEST = 'TEAMS_FETCH_MANY_REQUEST'
export const TEAMS_FETCH_MANY_SUCCESS = 'TEAMS_FETCH_MANY_SUCCESS'
export const TEAMS_FETCH_MANY_FAILURE = 'TEAMS_FETCH_MANY_FAILURE'

export const TEAMS_FETCH_MANY_NO_PAGINATE_REQUEST = 'TEAMS_FETCH_MANY_NO_PAGINATE_REQUEST'
export const TEAMS_FETCH_MANY_NO_PAGINATE_SUCCESS = 'TEAMS_FETCH_MANY_NO_PAGINATE_SUCCESS'
export const TEAMS_FETCH_MANY_NO_PAGINATE_FAILURE = 'TEAMS_FETCH_MANY_NO_PAGINATE_FAILURE'

export const TEAMS_FETCH_REQUEST = 'TEAMS_FETCH_REQUEST'
export const TEAMS_FETCH_SUCCESS = 'TEAMS_FETCH_SUCCESS'
export const TEAMS_FETCH_FAILURE = 'TEAMS_FETCH_FAILURE'

export const TEAMS_SAVE_REQUEST = 'TEAMS_SAVE_REQUEST'
export const TEAMS_SAVE_SUCCESS = 'TEAMS_SAVE_SUCCESS'
export const TEAMS_SAVE_FAILURE = 'TEAMS_SAVE_FAILURE'

export const EMPTY_TEAMS = 'EMPTY_TEAMS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
