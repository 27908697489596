export default {
  data ({ data }) {
    return data
  },
  calendarData ({ calendarData }) {
    return calendarData
  },
  errors ({ validationErrors }) {
    return validationErrors
  },
  isDeleting ({ deleting }) {
    return deleting
  },
  isLoading ({ loading }) {
    return loading
  },
  isCalendarLoading ({ calendarLoading }) {
    return calendarLoading
  },
  isSaving ({ saving }) {
    return saving
  },
  pagination ({ page }) {
    return page
  },
  single ({ single }) {
    return single
  }
}
