import {
  EMAILS_FETCH_MANY_REQUEST,
  EMAILS_FETCH_MANY_SUCCESS,
  EMAILS_FETCH_MANY_FAILURE,
  EMAILS_FETCH_REQUEST,
  EMAILS_FETCH_SUCCESS,
  EMAILS_FETCH_FAILURE,
} from './types'

export default {
  fetchMany ({ commit }, { query = '', page = 1, inboxType = null }) {
    commit(EMAILS_FETCH_MANY_REQUEST)
    return window.axios.get('/emails', { params: { query, page, inboxType } }).then(response => {
      commit(EMAILS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EMAILS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(EMAILS_FETCH_REQUEST)
    return window.axios.get(`/emails/${id}`).then(response => {
      commit(EMAILS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(EMAILS_FETCH_FAILURE, error)
      throw error
    })
  },
}
