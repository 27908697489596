import {
  EVENTS_BATCH_DELETE_REQUEST, EVENTS_BATCH_DELETE_SUCCESS, EVENTS_BATCH_DELETE_FAILURE,
  EVENTS_FETCH_MANY_REQUEST, EVENTS_FETCH_MANY_SUCCESS, EVENTS_FETCH_MANY_FAILURE,
  EVENTS_SAVE_REQUEST, EVENTS_SAVE_SUCCESS, EVENTS_SAVE_FAILURE,
  
  EMPTY_EVENTS,
  CLEAR_ERRORS
} from './types'

export default {
  [EVENTS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [EVENTS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [EVENTS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [EVENTS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [EVENTS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [EVENTS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EVENTS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [EVENTS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [EVENTS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_EVENTS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
