<template>
  <b-container fluid>
    <b-form>
      <b-row class="mb-3">
        <b-col cols="9" class="d-flex flow-row align-items-center justify-content-start">
          <h1 class="font-weight-bold text-primary">Ticket #{{ ticket.id }}</h1>
        </b-col>
        <b-col cols="3" class="d-flex flow-row align-items-center justify-content-end">
          <b-button :to="{name:'dash.tickets.update', params: { id: ticket.id }}" variant="secondary">Edit Ticket</b-button>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')">
                <b-form-input :disabled="true" :state="validationState(errors, 'name')" v-model="ticket.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Email" :invalid-feedback="validationInvalidFeedback(errors, 'email')">
                <b-form-input :disabled="true" :state="validationState(errors, 'email')" v-model="ticket.email" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Phone" :invalid-feedback="validationInvalidFeedback(errors, 'phone')">
                <b-form-input :disabled="true" :state="validationState(errors, 'phone')" v-model="ticket.phone" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="SPA Serial Number" :invalid-feedback="validationInvalidFeedback(errors, 'spa')">
                <b-form-input :disabled="true" :state="validationState(errors, 'spa')" v-model="ticket.spa" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Status" :invalid-feedback="validationInvalidFeedback(errors, 'status')">
                <b-form-input :disabled="true" :state="validationState(errors, 'status')" v-model="ticket.status" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Description of the Fault" :invalid-feedback="validationInvalidFeedback(errors, 'description')">
                <b-form-textarea :disabled="true" :state="validationState(errors, 'description')" v-model="ticket.description" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!isLoading">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('past_orders')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['past_orders'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Past Orders</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['past_orders']">
                  <b-col cols="12" v-if="ticket.customer.orders.length">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-for="(order, orderInd) in ticket.customer.orders" :key="`order${orderInd}`">
                      <b-col cols="12">
                        <b-card no-body class="mb-3 p-3">
                          <b-row>
                            <b-col cols="12">
                              <b-row>
                                <b-col class="d-flex flex-column align-items-start justify-content-center">
                                  <p class="h5 mb-0"><router-link v-b-popover.hover.left="'View Order.'" :to="{name: 'dash.orders.view', params: { id: order.id }}">Order #{{ order.id }}</router-link></p>
                                  <small class="mb-0 text-muted"><FriendlyDate :direction="'right'" :date="order.created_at"/></small>
                                </b-col>
                              </b-row>
                              <b-row v-if="order.finance_package_id" class="mt-2 mb-2">
                                <b-col cols="12">
                                  <div>
                                    <b-badge sm variant="primary">Finance</b-badge>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row v-if="order.additional_notes" class="mt-2 mb-2">
                                <b-col cols="12">
                                  <div class="d-flex flex-column">
                                    <small>Additional Notes:</small>
                                    <small class="text-muted white-space-pre">{{ order.additional_notes }}</small>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="mt-3">
                                <b-col cols="12">
                                  <h6 class="mb-2">Order Items:</h6>
                                  <b-card no-body class="p-3" v-for="item in order.items" :key="`orderItem${item.id}`">
                                    <b-row>
                                      <b-col cols="12">
                                        <div class="d-flex flex-column">
                                          <div class="d-flex flex-row flex-fill">
                                            <div class="flex-column flex-fill">
                                              <p class="mb-0 flex-fill">{{ item.product.name }}</p>
                                              <div class="flex-row">
                                                <b-badge variant="danger" class="mr-2" v-if="item.install_required" v-b-popover.hover.left="" pill>Installation Required</b-badge>
                                                <b-badge variant="danger" class="mr-2" v-if="item.crane_required" pill>Crane Required</b-badge>
                                                <b-badge variant="danger" class="mr-2" v-if="item.electrics_required" pill>Electrics Required</b-badge>
                                              </div>
                                              <p v-if="item.install_required" class="mb-0 mt-1 text-muted">Installation {{ item.install_at ? 'Scheduled: ' + moment(item.install_at).format('DD/MM/YYYY') : 'Not Scheduled.' }}</p>
                                            </div>
                                            <div class="flex-column text-right" v-if="item.supply_product">
                                              <p class="mb-0 text-muted">Expected {{ item.supply_product.expected_date ? moment(item.supply_product.expected_date).format('DD/MM/YYYY') : moment(item.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</p>
                                              <small class="mb-0 text-muted">{{ item.supply_product.supply.name }}</small>
                                            </div>
                                          </div>
                                          <div class="d-flex flex-column mt-3" v-if="item.extras.length">
                                            <b-card>
                                              <h6 class="mb-3">Extras:</h6>
                                              <b-table-simple small striped>
                                                <b-thead>
                                                  <b-tr>
                                                    <b-th>Name</b-th>
                                                    <b-th class="text-right"><span class="mr-2">Amount</span></b-th>
                                                  </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                  <b-tr v-for="(extra, extraInd) in item.extras" :key="`extra${item.id}-${extraInd}`">
                                                    <b-td>{{ extra.name }}</b-td>
                                                    <b-td class="text-right"><span class="mr-2">{{ extra.price | currencyFormat }}</span></b-td>
                                                  </b-tr>
                                                </b-tbody>
                                              </b-table-simple>
                                            </b-card>
                                          </div>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="!ticket.customer.orders.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no orders attached to this customer.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="ticket.ticket_address">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('address')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['address'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Address</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['address']">
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'ticket_address.address_line_1')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'ticket_address.address_line_1')" v-model="ticket.ticket_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'ticket_address.address_line_2')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'ticket_address.address_line_2')" v-model="ticket.ticket_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'ticket_address.city')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'ticket_address.city')" v-model="ticket.ticket_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'ticket_address.county')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'ticket_address.county')" v-model="ticket.ticket_address.county" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'ticket_address.postcode')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'ticket_address.postcode')" v-model="ticket.ticket_address.postcode" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
              <b-col cols="12">
                  <b-card>
                      <b-row>
                          <b-col class="d-flex flex-row" cols="12" md="6" @click.prevent="toggleCollapse('ticket_notes')" role="button">
                              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['ticket_notes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                              <div class="d-flex flex-column flex-fill ml-4">
                                  <p class="h4 font-weight-bold mb-0">Notes</p>
                              </div>
                          </b-col>
                      </b-row>
                      <b-row class="mt-4" v-if="!collapsed['ticket_notes']">
                          <!-- Add New Note Field -->
                          <b-col cols="12">
                              <hr class="mt-0" />
                              <b-form-group label="Add a Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')">
                                  <b-form-textarea :state="validationState(errors.errors, 'newNote')" v-model="newNote" placeholder="Insert note here..." size="sm"></b-form-textarea>
                              </b-form-group>
                              <b-form-group label="Status" :invalid-feedback="validationInvalidFeedback(errors.errors, 'status')">
                                  <b-form-select :disabled="isSaving" :options="statuses" :state="validationState(errors.errors, 'status')" v-model="newStatus" />
                              </b-form-group>
                              <div class="d-flex flex-row flex-fill">
                                <b-button variant="primary" block @click="addNote">Add Note</b-button>
                              </div>
                          </b-col>
                          <b-col cols="12" v-if="ticket.notes.length">
                              <hr />
                              <b-row v-for="(note, noteInd) in ticket.notes" :key="noteInd">
                                  <b-col cols="12" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note.' + noteInd + '.note')">
                                      <b-card no-body class="p-4 mt-3">
                                        <b-row>
                                          <b-col cols="11">
                                            <p class="note mb-0 white-space-pre">{{ note.note }}</p>
                                          </b-col>
                                          <b-col cols="1" class="d-flex flex-row align-items-start justify-content-end">
                                            <b-button variant="danger" @click="rmNote(noteInd)" size="sm"><fa-icon :icon="'trash'"/></b-button>
                                          </b-col>
                                        </b-row>
                                        <hr />
                                        <b-row>
                                            <b-col cols="9" class="d-flex flex-row justify-content-start align-items-center">
                                                <p class="note-created mb-0">Left by: {{ note.user.name }}</p>
                                            </b-col>
                                            <b-col cols="3" class="d-flex flex-row justify-content-end align-items-center">
                                                <p class="note-created text-muted mb-0"><FriendlyDate :date="note.created_at"></FriendlyDate></p>
                                            </b-col>
                                        </b-row>
                                      </b-card>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-card>
              </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!isLoading">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" sm="7" clsass="d-flex flex-row align-items-center">
                        <div class="w-100 d-flex flex-row" @click.prevent="toggleCollapse('correspondence')" role="button">
                          <b-icon class="cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['correspondence'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                          <div class="d-flex flex-column flex-fill ml-4">
                            <p class="h4 font-weight-bold mb-0">Correspondence</p>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" sm="5" class="d-flex flex-row align-items-center justify-content-start justify-content-sm-end">
                        <b-button v-b-modal.email-modal variant="outline-secondary" class="ml-5 mt-4 mt-md-0 ml-md-0"><fa-icon icon="envelope" /> Schedule Email</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['correspondence']">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row :class="ticket.customer.emails.length ? 'mt-4' : ''" v-if="!collapsed['correspondence']">
                  <b-col cols="12" v-if="!ticket.customer.emails.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There has been no correspondence with this customer.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="ticket.customer.emails.length">
                    <b-card no-body>
                      <b-row v-for="(email, emailInd) in ticket.customer.emails" :key="emailInd">
                        <b-col cols="12">
                          <b-row v-if="emailInd > 0">
                            <b-col cols="12">
                                <div class="m-3"><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                              <div class="mt-3 ml-3 mb-0 mb-md-3">
                                <h5>{{ email.subject }}</h5>
                                <h6>From: <small>{{ email.from_email }}</small></h6>
                                <h6>To: <small>{{ email.to_email }}</small></h6>
                              </div>
                            </b-col>
                            <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                              <div class="ml-3 mr-3 mt-0 mt-md-3">
                                <small class="text-right"><span class="text-muted">{{ moment(email.date).format('DD/MM/YYYY @ HH:MM') }}</span></small>
                              </div>
                              <b-button @click="replyingToEmail = email.id" variant="link" class="mr-3 mr-md-0 no-btn-shadow">Reply</b-button>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                                <div class="m-3"><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                                <div class="m-3">
                                  <iframe :ref="`iframe${email.id}`" v-if="!isLoading" :srcdoc="email.body" frameborder="0" class="w-100"></iframe>
                                </div>
                            </b-col>
                          </b-row>
                          <b-row v-if="email.attachments.length">
                            <b-col cols="12">
                                <div class="mb-3 ml-3 mr-3">
                                  <p>Attachments:</p>
                                  <div class="d-flex flex-row flex-fill flex-wrap">
                                    <a target="_blank" :href="`/attachment/${attachment.id}`" class="btn btn-outline-secondary mr-2 m2" v-for="(attachment, attachmentInd) in email.attachments" :key="attachmentInd"><fa-icon icon="download" /> {{ attachment.name }}</a>
                                  </div>
                                </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('image_collapse')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['image_collapse'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Images</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="!collapsed['image_collapse']">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="12" md="12">
                    <h4 class="py-3 m-0">Serial Number Image</h4>
                    <b-img class="ticket-media" :src="returnFileUrl('serial_number_image')" fluid block></b-img>
                  </b-col>
                  <b-col cols="12" md="12">
                    <h4 class="py-3 m-0">Hot Tub Image</h4>
                    <b-img class="ticket-media" :src="returnFileUrl('hot_tub_image')" fluid block></b-img>
                  </b-col>
                  <b-col cols="12" md="12">
                    <h4 class="py-3 m-0">Problem File</h4>
                    <b-img v-if="returnFileType('problem_file') == 'image'" :src="returnFileUrl('problem_file')" fluid block></b-img>
                    <video class="w-100 ticket-media" v-if="returnFileType('problem_file') == 'video'" controls>
                      <source type="video/mp4" :src="returnFileUrl('problem_file')">
                    </video>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('repairs_list')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['repairs_list'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Repairs</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end" cols="12" md="3">
                    <b-button block size="sm" variant="primary" :to="{ name: 'dash.tickets.book_repair', params: { id: ticket.id } }">Book a Repair</b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="!collapsed['repairs_list']">
                  <b-col cols="12" v-if="!ticket.repairs.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no repairs for this ticket.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="ticket.repairs.length">
                    <hr>
                    <b-list-group>
                      <b-list-group-item v-for="(repair, repairInd) in ticket.repairs" :key="repairInd">
                        <b-row>
                          <b-col class="d-flex flex-column" cols="12" md="9">
                            <p class="justify-content-end mb-0">{{ repair.repair_complete ? 'Repair Complete' : 'Repair Pending' }}</p>
                            <small class="justify-content-end mb-0" v-if="!repair.cancelled_at">Repair Scheduled <FriendlyDate :date="repair.repair_date" :omit-time="true" /></small>
                            <small class="justify-content-end text-danger mb-0" v-else>Cancelled</small>
                          </b-col>
                          <b-col class="d-flex flex-column justify-content-center" cols="12" md="3">
                            <b-button class="align-self-end m-0" size="sm" variant="primary" :to="{ name: 'dash.repairs.view', params: { id: repair.id } }">View</b-button>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <b-modal id="email-modal" centered vertical size="lg" @ok="queueEmail">
      <template #modal-header="{ cancel }">
        <div class="container-fluid mt-3 mb-3">
          <b-row>
            <b-col cols="7" class="flex flex-column flex-fill align-items-center justify-content-center">
              <h4>{{ replyingToEmail ? 'Schedule Reply to' : 'Schedule' }} Email</h4>
              <small>To: {{ ticket.customer.forename }} {{ ticket.customer.surname }} ({{ ticket.customer.email }})</small>
            </b-col>
            <b-col cols="5" class="d-flex flex-row flex-fill align-items-start justify-content-end">
              <b-button variant="danger" @click="cancel"><fa-icon icon="times" /></b-button>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #default>
        <div class="container-fluid">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-input placeholder="Subject" v-model="newEmailSubject"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="h3 font-weight-bold text-muted mb-0">Schedule Email</p>
                      </div>
                      <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                        <b-form-checkbox v-model="setEmailSchedule"></b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="setEmailSchedule">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group class="mb-0">
                      <b-form-datepicker placeholder="Scheduled Date" v-model="newEmailScheduledDateAt"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group class="mb-0">
                      <b-input placeholder="Scheduled Time (HH:MM)" v-model="newEmailScheduledTimeAt"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted mb-0">Template</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <template-search
                      :error-feed="errors.errors"
                      :error-key="'template_id'"
                      class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                      type="template"
                      @input="templateUpdated"
                      v-model="template"
                    />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <Quill :new-content="templateContent" v-model="newEmailContent"/>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button">
                    <div class="container-fluid">
                      <b-row>
                        <b-col md="7" class="d-flex flex-row" @click.prevent="toggleCollapse('email_attachments')">
                          <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['email_attachments'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                          <div class="d-flex flex-column flex-fill ml-4 justify-content-center">
                            <p class="h4 font-weight-bold mb-0">Attachments</p>
                          </div>
                        </b-col>
                        <b-col md="5" class="d-flex flex-row align-items-center justify-md-content-end pt-3 pt-md-0 pr-0">
                          <b-button variant="outline-secondary" :disabled="attachmentButtonClicked" @click="newFileUpload"><fa-icon icon="plus" /> Add Attachment</b-button>
                          <b-form-file v-show="false" @change="collapsed['email_attachments'] = false" autofocus v-for="(attachment, attachmentInd) in newEmailAttachments" v-model="newEmailAttachments[attachmentInd]" :key="attachmentInd" :id="`attachment-upload${attachmentInd}`"></b-form-file>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['email_attachments']">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-if="filteredNewEmailAttachments.length">
                      <b-col cols="12" class="">
                        <div class="d-flex flex-row flex-fill flex-wrap">
                          <span v-for="(attachment, attachmentInd) in newEmailAttachments" :key="`email-attachment${attachmentInd}`" v-show="attachment != null"><b-button :variant="hoveredAttachment == attachmentInd ? 'outline-danger' : 'outline-primary'" @mouseover="hoveredAttachment = attachmentInd" @mouseleave="hoveredAttachment = null" @click="rmAttachment(attachmentInd)" class="mr-2 mb-2" v-if="attachment != null"><fa-icon :icon="hoveredAttachment == attachmentInd ? 'times' : 'file-import'" class="mr-2"/>{{ attachment.name }}</b-button></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="!filteredNewEmailAttachments.length">
                      <b-col cols="12" class="d-flex justify-content-center">
                        <p class="mt-1 mb-0">There are no attachments currently selected.</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #modal-footer="{ ok }">
        <b-button @click="ok()"><fa-icon icon="paper-plane" /> Schedule</b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
import currentUser from '../../mixins/currentUser'
import TemplateSearch from '../../components/TemplateSearch';
import Quill from '../../components/Quill';

export default {
  mixins: [validation, momentMixin, currentUser],
  components: { FriendlyDate, TemplateSearch, Quill },
  computed: {
    ...mapGetters('tickets', ['errors', 'isLoading', 'isSaving', 'single']),
    filteredNewEmailAttachments() {
      return this.newEmailAttachments.filter(attachment => {
        return (attachment != null)
      })
    },
  },
  data () {
    return {
      collapsed: {
        address: true,
        repairs_list: true,
        image_collapse: true,
        ticket_notes: true,
        past_orders: true,
        correspondence: true,
      },

      statuses: [],

      newNote: null,
      newStatus: null,

      replyingToEmail: null,
      newEmailSubject: null,
      newEmailContent: null,
      newEmailScheduledDateAt: null,
      newEmailScheduledTimeAt: null,
      newEmailAttachments: [],
      attachmentButtonClicked: false,
      hoveredAttachment: null,
      setEmailSchedule: false,

      template: { id: null },
      templateContent: null,

      ticket: {
        name: null,
        email: null,
        phone: null,
        spa: null,
        status: null,
        description: null,
        serial_number_image: null,
        hot_tub_image: null,
        problem_file: null,
        accept_disclaimer: false,
        ticket_address: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          county: null,
          postcode: null,
        },
        notes: [],
      }
    }
  },
  created() {
    var self = this;
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id).then(response => {
        self.newStatus = response.data.status;
      });
    }
    this.fetchStatuses().then(response => {
      self.statuses = response.data;
    });
  },
  methods: {

    ...mapActions('tickets', ['fetch', 'fetchStatuses']),

    ...mapActions('customers', ['doQueueEmail']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    addNote() {
      if(this.newNote) {
        window.axios.post(`/tickets/${this.ticket.id}/note/add`, { note: this.newNote, newStatus: this.newStatus }).then(
          response => {
            if(response.data) {
              this.ticket.status = response.data.ticket.status;
              this.ticket.notes = [{
                  id: response.data.id,
                  note: this.newNote,
                  user: { name: this.currentUser.name },
                  created_at: response.data.created_at
                }, ...this.ticket.notes];
              this.newNote = null;
            }
          }
        )
      }
    },

    rmNote(ind) {
      if(this.ticket.notes[ind].id) {
        window.axios.post(`/tickets/${this.ticket.id}/note/delete/${this.ticket.notes[ind].id}`).then(
          response => {
            if(response.data) this.ticket.notes.splice(ind, 1)
          }
        )
      }else{
        this.ticket.notes.splice(ind, 1)
      }
    },
    
    returnFileUrl(fileKey) {
      if(this.ticket[fileKey]) {
        return this.ticket[fileKey].url ?? URL.createObjectURL(this.ticket[fileKey]);
      }
    },

    returnFileType(fileKey) {
      if(this.ticket[fileKey]) {
        return this.ticket[fileKey].mimetype.split('/')[0];
      }
    },

    queueEmail() {
      let data = new FormData()

      data.append('subject', this.newEmailSubject)
      data.append('content', this.newEmailContent)
      data.append('scheduled_date', !this.setEmailSchedule ? this.moment().format('YYYY-MM-DD') : this.newEmailScheduledDateAt)
      data.append('scheduled_time', !this.setEmailSchedule ? this.moment().format('HH:MM') : this.newEmailScheduledTimeAt)
      data.append('replying_to', this.replyingToEmail ?? '')

      this.newEmailAttachments.forEach((attachment, attachmentInd) => {
        if(attachment != null) data.append(`attachments[${attachmentInd}]`, attachment)
      })
      
      this.doQueueEmail({ 'customer': this.ticket.customer.id, data}).then(response => {
        //
      })
    },

    templateUpdated() {
      this.templateContent = this.template.delta_content
    },

    newFileUpload() {
      this.attachmentButtonClicked = true
      this.newEmailAttachments.push(null)
      this.$nextTick().then(() => {
        document.getElementById(`attachment-upload${this.newEmailAttachments.length-1}`).click()
        setTimeout(() => {
          this.attachmentButtonClicked = false
        }, 500)
      })
    },
  },
  mounted() {
    this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
    this.newEmailScheduledTimeAt = this.moment().format('HH:MM')
    window.addEventListener('resize', event => { 
      if (typeof this.resizeIFrames === "function") { 
        this.resizeIFrames();
      }
    })
    this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
      if(modalId == 'email-modal') {
        this.replyingToEmail = null
        this.newEmailSubject = null
        this.newEmailContent = null
        this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
        this.newEmailScheduledTimeAt = this.moment().format('HH:MM')
        this.newEmailAttachments = []
        this.attachmentButtonClicked = false
        this.hoveredAttachment = null
        this.template = { id: null }
        this.templateContent = null
      }
    })
  },
  watch: {
    single () {
      this.ticket = this.single
    },

    replyingToEmail() {
      if(this.replyingToEmail) this.$root.$emit('bv::show::modal', 'email-modal')
    },
  },
  filters: {
    currencyFormat: v => ( '£' + Number(v).toFixed(2) )
  }
}
</script>

<style>
  .ticket-media {
    max-height: 90vh;
  }
</style>
