<template>
    <b-row class="d-block h-100">
        <b-col cols="12">
            <div ref="editor"></div>
        </b-col>
    </b-row>
</template>

<script>
import Quill from 'quill'
import Clipboard from 'quill/modules/clipboard'

export default {
  props: ['value', 'deltaContent', 'newContent'],
  data: () => ({
      editor: null,
  }),
  mounted() {
    this.editor = new Quill(this.$refs['editor'], {
        bounds: '.modal-body',
        modules: {
            'clipboard': {
                matchers: [],
            },
            toolbar: [
                // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                // [{ 'font': [] }],
                // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                // ['blockquote', 'code-block'],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                // [{ 'direction': 'rtl' }],                         // text direction


                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'align': [] }],

                ['clean'] 
            ],
        },
        theme: 'snow'
    })

    var editor = this.$refs['editor'].getElementsByClassName('ql-editor')[0]
    editor.style.position = 'absolute'
    editor.style.width = '100%'

    if(this.deltaContent) this.editor.setContents(JSON.parse(this.deltaContent).ops)
    this.editor.on('text-change', (delta, oldDelta, source) => {
        this.$emit('input', editor.innerHTML)
        this.$emit('delta', JSON.stringify(delta))
    })
  },
  watch: {
    newContent(v) {
      if(v) {
        var content = JSON.parse(v)
        if(content.hasOwnProperty('ops')) this.editor.setContents(content.ops)
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

.ql-container {
    min-height: 250px;
}

</style>