import pagination from '../../utils/pagination'
import {
  REPAIRS_BATCH_DELETE_REQUEST, REPAIRS_BATCH_DELETE_SUCCESS, REPAIRS_BATCH_DELETE_FAILURE,
  REPAIRS_FETCH_MANY_REQUEST, REPAIRS_FETCH_MANY_SUCCESS, REPAIRS_FETCH_MANY_FAILURE,
  REPAIRS_FETCH_CALENDAR_REQUEST, REPAIRS_FETCH_CALENDAR_SUCCESS, REPAIRS_FETCH_CALENDAR_FAILURE,
  REPAIRS_FETCH_MAP_REQUEST, REPAIRS_FETCH_MAP_SUCCESS, REPAIRS_FETCH_MAP_FAILURE,
  REPAIRS_FETCH_REQUEST, REPAIRS_FETCH_SUCCESS, REPAIRS_FETCH_FAILURE,
  REPAIRS_SAVE_REQUEST, REPAIRS_SAVE_SUCCESS, REPAIRS_SAVE_FAILURE,
  REPAIRS_PROCESS_REQUEST, REPAIRS_PROCESS_SUCCESS, REPAIRS_PROCESS_FAILURE,
  REPAIRS_CALENDAR_REQUEST, REPAIRS_CALENDAR_SUCCESS, REPAIRS_CALENDAR_FAILURE,

  EMPTY_REPAIRS,
  CLEAR_ERRORS
} from './types'

export default {
  [REPAIRS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [REPAIRS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [REPAIRS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [REPAIRS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [REPAIRS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [REPAIRS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [REPAIRS_FETCH_CALENDAR_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [REPAIRS_FETCH_CALENDAR_SUCCESS] (state, { data }) {
    state.data = data.data
    state.loading = false
  },
  [REPAIRS_FETCH_CALENDAR_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [REPAIRS_FETCH_MAP_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [REPAIRS_FETCH_MAP_SUCCESS] (state, { data }) {
    state.data = data.data
    state.loading = false
  },
  [REPAIRS_FETCH_MAP_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [REPAIRS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [REPAIRS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [REPAIRS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [REPAIRS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [REPAIRS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [REPAIRS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [REPAIRS_PROCESS_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [REPAIRS_PROCESS_SUCCESS] (state) {
    state.saving = false
  },
  [REPAIRS_PROCESS_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [REPAIRS_CALENDAR_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.calendarLoading = false
  },
  [REPAIRS_CALENDAR_REQUEST] (state) {
    state.calendarData = []
    state.calendarLoading = true
  },
  [REPAIRS_CALENDAR_SUCCESS] (state, { data }) {
    state.calendarData = data
    state.calendarLoading = false
  },
  [EMPTY_REPAIRS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
